import $ from 'jquery';
import { TimelineLite, Power2 } from 'gsap';
import AnimationUtils from '../utils/animation-utils';
import Utils from '../utils/utils';

export default class ConseilsMenu {
  static init() {
    this.initInteractions();
  }

  static initInteractions() {
    this.$container = $('.conseils-nav');

    if (this.$container.length === 0) {
      return;
    }

    this.$labels = this.$container.find('.category-label');
    this.$mobileButton = this.$container.find('.conseils-mobile-button');
    this.$conseilsBlock = this.$container.find('.conseils-container');
    this.$mobileClose = this.$conseilsBlock.find('.conseils-header .close');

    this.$labels.on('click', (event) => {
      const $categoryContainer = $(event.currentTarget).parent('.conseil-category');
      this.toggleCriterion($categoryContainer);
    });

    if ($('body').width() < Utils.breakpoints().lg) {
      this.$mobileButton.on('click', () => this.openMobileModal());
      this.$mobileClose.on('click', () => this.closeMobileModal());
    }
  }

  static toggleCriterion($categoryContainer) {
    if ($categoryContainer.hasClass('js-opened')) {
      this.closeCriterion($categoryContainer);
    } else {
      this.openCriterion($categoryContainer);
    }
  }

  static openCriterion($categoryContainer) {
    const $categories = $categoryContainer.find('.category-container');
    AnimationUtils.animateHeightToAuto($categories, 0.4);
    $categoryContainer.addClass('js-opened');
  }

  static closeCriterion($categoryContainer) {
    const $categories = $categoryContainer.find('.category-container');
    AnimationUtils.animateHeightToZero($categories, 0.4);
    $categoryContainer.removeClass('js-opened');
  }

  static openMobileModal() {
    const timeline = new TimelineLite();

    timeline
      .set(this.$conseilsBlock, {
        display: 'block',
      })
      .fromTo(this.$conseilsBlock, 0.5, {
        x: '100%',
      }, {
        x: '0%',
        ease: Power2.easeOut,
      });
  }

  static closeMobileModal() {
    const timeline = new TimelineLite();

    timeline
      .fromTo(this.$conseilsBlock, 0.5, {
        x: '0%',
      }, {
        x: '100%',
        ease: Power2.easeInOut,
      })
      .set(this.$conseilsBlock, {
        display: 'none',
      });
  }
}
