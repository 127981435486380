<template>
    <div class="product-giftcard-reception-container">
        <p class="variant-label" v-html="wordings.label"></p>
        <form>
            <div class="radio-variant">
                <div class="form-control radio-container">
                    <input
                        type="radio"
                        name="reception_mode"
                        id="reception_mode_email"
                        :checked="reception_mode == 'email'"
                        value="email"
                        @change="changeReceptionMode"
                        v-model="item.reception_mode"
                    />
                    <label for="reception_mode_email" class="control-label" v-html="wordings.reception_email"></label>
                </div>

                <div :class="{visible: reception_mode == 'email'}" class="email-container">
                    <div class="floating-label">
                        <input
                            type="text"
                            name="beneficiare_email"
                            id="beneficiare_email"
                            placeholder="example@mail.com"
                            v-model="item.beneficiare_email"
                        />
                        <label class="form-control-label" for="beneficiare_email" v-html="wordings.email"></label>
                    </div>
                </div>

                <div class="form-control radio-container">
                    <input
                        type="radio"
                        name="reception_mode"
                        id="reception_mode_coffret"
                        :checked="reception_mode == 'coffret'"
                        value="coffret"
                        @change="changeReceptionMode"
                        v-model="item.reception_mode"
                    />
                    <label for="reception_mode_coffret" class="control-label" v-html="`${wordings.reception_coffret} (+${customizations_fields.coffret.price} ${currency.sign})`"></label>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            customizations_fields: {
                type: Object
            },
            wordings: {
                type: Object
            },
            currency: {
                type: Object
            }
        },
        data() {
            return {
                item: {
                    id_product: null,
                    id_customization: null,
                    reception_mode: null,
                    beneficiare_email: null
                },
                reception_mode: null,
                errorMessages: [],
                loading: false
            }
        },
        mounted() {
            $('body').on('click', '[data-button-action="add-to-cart"]', event => {
                event.preventDefault();
                this.$eventBus.$emit('hide-errors');

                if(!this.validate()) {
                    this.$eventBus.$emit('show-errors', this.errorMessages);
                    event.stopImmediatePropagation();
                    return;
                }

                // on sauvegarde la customization
                if(!this.item.id_customization) {
                    event.stopImmediatePropagation();
                    this.saveCustomization();
                    return;
                }
            });

            prestashop.on('updateCart', (event) => {
                this.reception_mode = this.item.reception_mode = null;
            });
        },
        watch: {
            reception_mode(after, before) {
                if (after) {
                    this.$eventBus.$emit('hide-errors');
                }
            }
        },
        methods: {
            changeReceptionMode(event) {
                this.reception_mode = $(event.target).val();
            },
            validate() {
                this.errorMessages = [];

                if(!this.item.reception_mode) {
                    this.errorMessages.push('empty_reception_mode');
                }

                if(this.item.reception_mode == 'email' && !this.item.beneficiare_email) {
                    this.errorMessages.push('empty_recipient_email');
                }

                if (this.errorMessages.length > 0) {
                    return false;
                }

                return true;
            },
            saveCustomization() {
                this.loading = true;

                this.item.id_product = $('#product_page_product_id').val();
                this.item.id_customization = $('#product_customization_id').val();

                let url = prestashop.urls.base_url + 'modules/md_giftcard/ajax.php';

                let data = {
                    'action': 'saveCustomizations',
                    'item': this.item,
                    'customizations_fields': this.customizations_fields
                };

                return axios({
                    method: 'post',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                }).then((response) => {
                    this.loading = false;
                    if(response.data.id_customization) {
                        this.setCustomization(response.data.id_customization);
                        $('[data-button-action="add-to-cart"]').trigger('click');
                    } else {
                        this.showErrors(['unknown']);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.showErrors(['unknown']);
                });
            },
            setCustomization(id_customization) {
                $('#product_customization_id').val(id_customization);
                this.$emit('setCustomization', id_customization);
            },
            showErrors(idsMessage) {
                this.errorMessages = idsMessage;
                this.$eventBus.$emit('show-errors', this.errorMessages);
            }
        }
    }
</script>