import $ from 'jquery';
import AnimationUtils from '../utils/animation-utils';
import Utils from '../utils/utils';

export default class FooterMenuMonbile {
  static init() {
    if (Utils.isWindowLarger('md') && !$('body').hasClass('touch-device')) {
      return;
    }

    this.$linkBlocks = $('.main-footer .link-block');
    this.$linkBlockTitles = this.$linkBlocks.find('.link-block-title');

    this.$linkBlockTitles.on('click', (event) => {
      const $block = $(event.currentTarget).parent('.link-block');
      this.toggleBlock($block);
    });
  }

  static toggleBlock($block) {
    $block.toggleClass('js-opened');
    const $links = $block.find('.link-list');

    if ($block.hasClass('js-opened')) {
      AnimationUtils.animateHeightToAuto($links);
    } else {
      AnimationUtils.animateHeightToZero($links);
    }
  }
}
