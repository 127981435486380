import $ from 'jquery';
import AnimationUtils from '../utils/animation-utils';

export default class FAQ {
  static init() {
    this.$faqContainer = $('.faq-container');

    if (this.$faqContainer.length === 0) {
      return;
    }

    this.$faqCategories = this.$faqContainer.find('.category-name');

    this.$faqCategories.on('click', (event) => {
      const $categoryContainer = $(event.currentTarget).parent('.category-container');
      this.toggleCategory($categoryContainer);
    });
  }

  static toggleCategory($categoryContainer) {
    if ($categoryContainer.hasClass('js-opened')) {
      this.closeCategory($categoryContainer);
    } else {
      this.openCategory($categoryContainer);
    }
  }

  static openCategory($categoryContainer) {
    $categoryContainer.addClass('js-opened');
    const $questions = $categoryContainer.find('.questions');
    AnimationUtils.animateHeightToAuto($questions, 0.4);
  }

  static closeCategory($categoryContainer) {
    $categoryContainer.removeClass('js-opened');
    const $questions = $categoryContainer.find('.questions');
    AnimationUtils.animateHeightToZero($questions, 0.4);
  }
}
