<template>
    <div class="product-giftcard-container">
        <p class="variant-label">{{wordings.label}}</p>

        <button type="button" class="giftcard-button" data-open-modal="product-giftcard-modal">
            {{wordings.message}}
            <i class="icon icon-arrow-right" v-if="!this.hasMessage"></i>
            <i class="icon icon-pen" v-if="this.hasMessage"></i>
        </button>

        <div class="modal-container half-modal hidden" id="product-giftcard-modal"  role="dialog" aria-hidden="true">
            <div class="overlay"></div>
            <div class="modal-content">
                <div class="close-btn" data-close-modal></div>

                <div class="modal-content-wrapper">
                    <md-product-giftcard-modal
                        :customizations_fields="customizations_fields"
                        :wordings="wordings"
                        @setCustomization="setCustomization"
                    >
                        <slot slot="title" name="title"></slot>
                        <slot slot="desc" name="desc"></slot>
                    </md-product-giftcard-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            customizations_fields: {
                type: Object
            },
            wordings: {
                type: Object
            }
        },
        data() {
            return {
                hasMessage: false
            }
        },
        mounted() {
            $("body").on("click", '[data-button-action="add-to-cart"]', event => {
                event.preventDefault();
                this.$eventBus.$emit('hide-errors');

                if (!this.hasMessage) {
                    this.$eventBus.$emit('show-errors', ['empty_message']);
                    event.stopImmediatePropagation();
                    return;
                }
            });
        },
        methods: {
            setCustomization(id_customization) {
                if (!id_customization) {
                    this.hasMessage = false;
                } else {
                    this.$eventBus.$emit('hide-errors');
                    this.hasMessage = true;
                }
            }
        }
    }
</script>
