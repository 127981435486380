<template>
    <div class="error-container" v-if="showErrorMessage">
        <p class="error-message" v-for="messageId in errorMessages" :key="messageId" v-html="wordings.errors[messageId]"></p>
    </div>
</template>

<script>
    export default {
        props: {
            wordings: {
                type: Object
            }
        },
        data() {
            return {
                errorMessages: [],
                showErrorMessage: false
            }
        },
        mounted() {
            this.$eventBus.$on('show-errors', (errors) => {
                this.showErrors(errors);
            });
            this.$eventBus.$on('hide-errors', () => {
                this.hideErrors();
            });
        },
        methods: {
            showErrors(errors) {
                this.errorMessages = errors;
                this.showErrorMessage = true;
            },
            hideErrors() {
                this.errorMessages = [];
                this.showErrorMessage = false;
            }
        }
    }
</script>