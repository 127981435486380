/* eslint-disable */
import $ from 'jquery';


export default class TermsTargetBlank {
  static init() {
    this.$section = $('#cta-terms-and-conditions-0');

    if (this.$section.length === 0) {
      return;
    }

    this.$section.on('click', function(e) {
      e.preventDefault();
      const url = $(this).prop('href');
      window.open(url, '_blank');
    });
  }
}