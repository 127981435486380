var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "giftcard-modal form-modal",
      class: { loading: _vm.loading }
    },
    [
      _vm.loading ? _c("div", { staticClass: "loader" }) : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "modal-title" }, [_vm._t("title")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "rte-content intro" }, [_vm._t("desc")], 2),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "floating-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.expediteur,
                  expression: "item.expediteur"
                }
              ],
              staticClass: "input",
              class: { error: _vm.hasError("buyername") },
              attrs: {
                id: "buyername",
                name: "buyername",
                type: "text",
                placeholder: _vm.wordings.buyername_placeholder
              },
              domProps: { value: _vm.item.expediteur },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "expediteur", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "form-control-label",
              attrs: { for: "buyername" },
              domProps: { innerHTML: _vm._s(_vm.wordings.buyername) }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "floating-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.beneficiare,
                  expression: "item.beneficiare"
                }
              ],
              staticClass: "input",
              class: { error: _vm.hasError("recipientname") },
              attrs: {
                type: "text",
                name: "recipientname",
                id: "recipientname",
                placeholder: " "
              },
              domProps: { value: _vm.item.beneficiare },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "beneficiare", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "form-control-label",
              attrs: { for: "recipientname" },
              domProps: { innerHTML: _vm._s(_vm.wordings.recipientname) }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label form-control-label-textarea",
              attrs: { for: "message" }
            },
            [
              _vm._v(_vm._s(_vm.wordings.text) + " "),
              _c("span", {
                staticClass: "optional",
                domProps: { innerHTML: _vm._s(_vm.wordings.text_suffix) }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "count-textarea" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.message,
                  expression: "item.message"
                }
              ],
              staticClass: "form-control form-control-textarea bordered",
              attrs: {
                rows: "5",
                id: "message",
                name: "message",
                maxlength: _vm.char_max
              },
              domProps: { value: _vm.item.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "message", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(_vm.item.message ? _vm.item.message.length : 0) + " / "
              ),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.char_max + " " + _vm.wordings.letters)
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: { click: _vm.validate }
            },
            [
              _c("span", { staticClass: "btn-content" }, [
                _vm._v(_vm._s(_vm.wordings.validate) + " "),
                _c("i", { staticClass: "icon icon-arrow-right" })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm.showErrorMessage
          ? _c(
              "div",
              { staticClass: "error-container" },
              _vm._l(_vm.errorMessages, function(messageId) {
                return _c(
                  "p",
                  { key: messageId, staticClass: "error-message" },
                  [_vm._v(_vm._s(_vm.wordings.errors[messageId]))]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }