import $ from 'jquery';
import {
  TimelineLite,
  Power0,
  Power2,
  TimelineMax,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import AnimationUtils from '../utils/animation-utils';
import Utils from '../utils/utils';

export default class PageSavoirFaire {
  static init() {
    if (!$('body').hasClass('page-savoir-faire')) {
      return;
    }

    this.$page = $('.main-content');

    this.$listContainer = this.$page.find('.section-list .list-container');
    this.$listItems = this.$listContainer.find('.list-item');
    this.$listImages = this.$page.find('.section-list .images-container .image');

    this.controller = new ScrollMagic.Controller();

    this.initListInteractions();

    this.initIntroParalax();
    this.initImageApparition(this.$page.find('.section-text-image .image-column'));
    this.initImageApparition(this.$page.find('.section-image-text .image-column'));
    this.initListApparition();
    this.initImagesParalax(this.$page.find('.section-dark-background .image-column'), true);
    this.initImageApparition(this.$page.find('.section-text-image-alt .image-column'));
    this.initScrollCarousel();
  }

  static initIntroParalax() {
    AnimationUtils.innerImageParalax(this.controller, this.$page.find('.intro-full-image')[0]);
  }

  static initImageApparition($imageColumn) {
    const timeline = new TimelineLite();

    const $imageContainer = $imageColumn.find('.image-container');
    const $imageLegend = $imageColumn.find('.image-legend');

    timeline
      .add(AnimationUtils.voletImageSimpleApparition($imageContainer, 'bottom'), 0);

    if ($imageLegend.length > 0) {
      timeline
        .fromTo($imageLegend, 0.4, {
          autoAlpha: 0,
          y: 10,
        }, {
          autoAlpha: 1,
          y: 0,
        }, '-=0.3');
    }

    new ScrollMagic.Scene({
      triggerElement: $imageColumn[0],
      triggerHook: 0.7,
      reverse: false,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initListApparition() {
    const scene = new ScrollMagic.Scene({
      triggerElement: this.$listContainer[0],
      triggerHook: 0.7,
      reverse: false,
    })
      .addTo(this.controller);

    scene.on('enter', () => {
      this.$listContainer.addClass('is-init');
      this.onMouseEnter(this.$listItems.first());
    });
  }

  static initImagesParalax($imageColumn, reverse = false) {
    const timeline = new TimelineLite();

    const $imageContainer = $imageColumn.find('.image-paralax .image');

    const paralaxRatio = 70;

    const mult = reverse ? -1 : 1;

    timeline
      .fromTo($imageContainer, 1, {
        y: -mult * paralaxRatio,
      }, {
        y: mult * paralaxRatio,
        ease: Power0.easeNone,
      }, 0);

    new ScrollMagic.Scene({
      triggerElement: $imageColumn[0],
      triggerHook: 1,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initScrollCarousel() {
    const timeline = new TimelineMax();

    const $carouselContainer = this.$page.find('.section-dark-background .scroll-carousel');
    const $scrollCarousel = $carouselContainer.find('.carousel');


    if (Utils.isWindowLarger('md')) {
      timeline
        .fromTo($scrollCarousel, 1, {
          x: '-30%',
          y: 0,
        }, {
          x: '-130%',
          y: Utils.isWindowLarger('md') ? 150 : 0,
          ease: Power0.easeNone,
        }, 0);
    } else {
      timeline
        .fromTo($scrollCarousel, 1, {
          x: '-30%',
        }, {
          x: '-130%',
          ease: Power0.easeNone,
        }, 0);
    }

    new ScrollMagic.Scene({
      triggerElement: $carouselContainer[0],
      triggerHook: 1,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initListInteractions() {
    this.$listItems.on('mouseenter', event => this.onMouseEnter($(event.currentTarget)));
  }

  static onMouseEnter($listItem) {
    const index = $listItem.data('index');

    if (this.currentIndex === index) {
      return;
    }

    this.currentIndex = index;
    this.$listItems.filter('.current').removeClass('current');
    this.$listContainer.addClass('over');
    $listItem.addClass('current');

    this.showImage(`image-${index}`);
  }

  static showImage(imageClass) {
    const $previousImage = this.$listImages.filter('.current');
    const $nextImage = this.$listImages.filter(`.${imageClass}`);
    $previousImage.removeClass('current');
    $nextImage.addClass('current');

    if (this.imageTimeline) {
      this.imageTimeline.progress(1);
      this.imageTimeline.kill();
    }
    this.imageTimeline = new TimelineLite();

    this.imageTimeline
      .set(this.$listImages, {
        zIndex: 0,
      })
      .set($nextImage.first(), {
        zIndex: 5,
        display: 'block',
      });

    if ($previousImage.length > 0) {
      this.imageTimeline
        .set($previousImage, {
          zIndex: 4,
        })
        .fromTo($previousImage, 0.6, {
          x: '0%',
        }, {
          x: '50%',
          ease: Power2.easeInOut,
        }, 0);
    }

    this.imageTimeline
      .fromTo($nextImage, 0.6, {
        x: '-100%',
      }, {
        x: '0%',
        ease: Power2.easeInOut,
      }, 0);

    if ($previousImage.length > 0) {
      this.imageTimeline
        .set($previousImage, {
          zIndex: 0,
          display: 'none',
        });
    }
  }
}
