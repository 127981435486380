import $ from 'jquery';
import {
  TimelineLite,
  Power0,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import Utils from '../utils/utils';
import AnimationUtils from '../utils/animation-utils';

export default class PageBoutiques {
  static init() {
    this.$sectionShops = $('.section-boutiques-shops');

    if (this.$sectionShops.length === 0) {
      return;
    }

    this.initSchedules();

    this.$sectionPictures = $('.section-boutiques-press');
    this.$galleryItems = this.$sectionPictures.find('.gallery-item');
    this.directions = [
      'top',
      'right',
      'bottom',
      'left',
    ];

    this.controller = new ScrollMagic.Controller();

    this.initApparitionOnScroll();
    this.initLogoParalax();
    this.initParalax();
  }

  static initSchedules() {
    this.$schedulesLinks = $('.schedule-link');

    this.$schedulesLinks.on('click', (event) => {
      event.preventDefault();
      const $link = $(event.currentTarget);
      const $scheduleDetails = $link.siblings('.schedule-details');

      $link.toggleClass('open');

      if ($link.hasClass('open')) {
        AnimationUtils.animateHeightToAuto($scheduleDetails, 0.4);
      } else {
        AnimationUtils.animateHeightToZero($scheduleDetails, 0.4);
      }
    });
  }

  static initApparitionOnScroll() {
    this.$galleryItems.each((index, galleryItem) => {
      const timeline = new TimelineLite();
      const direction = this.getRandomDirection();
      const imageContainer = $(galleryItem).find('.image-container');

      timeline
        .add(AnimationUtils.voletImageApparition(imageContainer, direction, 0.6, 0.6));

      new ScrollMagic.Scene({
        triggerElement: galleryItem,
        triggerHook: 0.7,
        reverse: false,
      })
        .setTween(timeline)
        .addTo(this.controller);
    });
  }

  static getRandomDirection() {
    let index = Utils.randomInt(0, this.directions.length);
    let direction = this.directions[index];
    if (this.previousDirection === direction) {
      index = (index + 1) % this.directions.length;
      direction = this.directions[index];
    }
    this.previousDirection = direction;
    return direction;
  }

  static initLogoParalax() {
    const $logoParalax = $('.logo-background');
    const timeline = new TimelineLite({
      onStart: () => {
        $logoParalax.css('display', 'block');
      },
    });

    $logoParalax.css('display', 'none');

    timeline
      .fromTo($logoParalax[0], 0.5, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
      }, 0)
      .fromTo($logoParalax[0], 5, {
        x: 0,
      }, {
        x: '-50%',
        ease: Power0.easeNone,
      }, 0)
      .fromTo($logoParalax[0], 0.5, {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
      }, '-=0.5');

    // Paralax
    new ScrollMagic.Scene({
      triggerElement: this.$sectionPictures[0],
      triggerHook: 0.5,
      offset: 150,
      duration: this.$sectionPictures.outerHeight() - 150,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initParalax() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }

    const paralaxRatios = [
      -100,
      150,
      0,
      200,
      0,
      200,
    ];
    this.$galleryItems.each((index, galleryItem) => {
      const timeline = new TimelineLite();

      const imageContainer = $(galleryItem).find('.image-container').first();

      let paralaxRatio = paralaxRatios[index];

      if ($('body').width() < Utils.breakpoints().lg) {
        paralaxRatio = Math.ceil(paralaxRatio / 5);
      }

      timeline
        .fromTo(imageContainer, 1, {
          y: paralaxRatio,
        }, {
          y: -paralaxRatio,
          ease: Power0.easeNone,
        });

      new ScrollMagic.Scene({
        triggerElement: galleryItem,
        triggerHook: 1,
        duration: '200%',
      })
        .setTween(timeline)
        .addTo(this.controller);
    });
  }
}
