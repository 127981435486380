import $ from 'jquery';
import lazySizes from 'lazysizes';

export default class LazyLoad {
  static init() {
    lazySizes.cfg.srcAttr = 'data-lazy-src';
    lazySizes.cfg.srcsetAttr = 'data-lazy-srcset';

    $('[data-lazy-src]').addClass('lazyload');
  }

  static checkElements() {
    $('[data-lazy-src]').addClass('lazyload');
    lazySizes.loader.checkElems();
  }

  static unveilImages($images) {
    $images.forEach(($image) => {
      this.unveilImage($image);
    });
  }

  static unveilImage($image) {
    lazySizes.loader.unveil($image);
  }
}
