var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "gravure-form form" }, [
    _vm.field_message
      ? _c("div", { staticClass: "form-group inline-form-group" }, [
          _c("label", { staticClass: "form-control-label" }, [
            _vm._v(_vm._s(_vm.field_message.label))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item["gravure_" + _vm.gravure_id + "_message"],
                  expression: "item[`gravure_${gravure_id}_message`]"
                }
              ],
              staticClass: "input",
              attrs: {
                type: "text",
                name:
                  "customizations_fields[" +
                  _vm.field_message.id_customization_field +
                  "]",
                placeholder: _vm.placeholders.message,
                maxlength: _vm.field_message.maxlength
              },
              domProps: {
                value: _vm.item["gravure_" + _vm.gravure_id + "_message"]
              },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.item,
                    "gravure_" + _vm.gravure_id + "_message",
                    $event.target.value
                  )
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(
                  _vm.item["gravure_" + _vm.gravure_id + "_message"]
                    ? _vm.item["gravure_" + _vm.gravure_id + "_message"].length
                    : 0
                ) +
                  " / " +
                  _vm._s(_vm.char_max)
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.field_emplacement
      ? _c(
          "div",
          { staticClass: "form-group inline-form-group emplacements-group" },
          [
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v(_vm._s(_vm.field_emplacement.label))
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "radios-container emplacements-container" },
              _vm._l(_vm.field_emplacement.values, function(emplacement) {
                return _c(
                  "li",
                  {
                    key: emplacement.id_emplacement,
                    staticClass: "radio-container"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.item[
                              "gravure_" + _vm.gravure_id + "_emplacement"
                            ],
                          expression:
                            "item[`gravure_${gravure_id}_emplacement`]"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        name:
                          "customizations_fields[" +
                          _vm.field_emplacement.id_customization_field +
                          "]",
                        id:
                          "customizations_fields_" +
                          _vm.field_emplacement.id_customization_field +
                          "_" +
                          emplacement.id_emplacement,
                        disabled:
                          _vm.gravure_id === 2 &&
                          _vm.item.gravure_1_emplacement ===
                            emplacement.id_emplacement
                      },
                      domProps: {
                        value: emplacement.id_emplacement,
                        checked: _vm._q(
                          _vm.item[
                            "gravure_" + _vm.gravure_id + "_emplacement"
                          ],
                          emplacement.id_emplacement
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.item,
                            "gravure_" + _vm.gravure_id + "_emplacement",
                            emplacement.id_emplacement
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for:
                            "customizations_fields_" +
                            _vm.field_emplacement.id_customization_field +
                            "_" +
                            emplacement.id_emplacement
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.images_path + "/" + emplacement.image,
                            alt: ""
                          }
                        }),
                        _vm._v("\n                " + _vm._s(emplacement.title))
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.field_type
      ? _c("div", { staticClass: "form-group inline-form-group" }, [
          _c("label", { staticClass: "form-control-label" }, [
            _vm._v(_vm._s(_vm.field_type.label))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "radios-container" },
            _vm._l(_vm.field_type.values, function(label, value) {
              return _c("li", { key: value, staticClass: "radio-container" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item["gravure_" + _vm.gravure_id + "_type"],
                      expression: "item[`gravure_${gravure_id}_type`]"
                    }
                  ],
                  attrs: {
                    type: "radio",
                    name:
                      "customizations_fields[" +
                      _vm.field_type.id_customization_field +
                      "]",
                    id:
                      "customizations_fields_" +
                      _vm.field_type.id_customization_field +
                      "_" +
                      value
                  },
                  domProps: {
                    value: value,
                    checked: _vm._q(
                      _vm.item["gravure_" + _vm.gravure_id + "_type"],
                      value
                    )
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(
                        _vm.item,
                        "gravure_" + _vm.gravure_id + "_type",
                        value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: {
                      for:
                        "customizations_fields_" +
                        _vm.field_type.id_customization_field +
                        "_" +
                        value
                    }
                  },
                  [_vm._v(_vm._s(label))]
                )
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.field_typographie
      ? _c(
          "div",
          { staticClass: "form-group inline-form-group typographie-radios" },
          [
            _c("label", { staticClass: "form-control-label" }, [
              _vm._v(_vm._s(_vm.field_typographie.label))
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "radios-container" },
              _vm._l(_vm.field_typographie.values, function(label, value) {
                return _c(
                  "li",
                  { key: value, staticClass: "radio-container" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.item[
                              "gravure_" + _vm.gravure_id + "_typographie"
                            ],
                          expression:
                            "item[`gravure_${gravure_id}_typographie`]"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        name:
                          "customizations_fields[" +
                          _vm.field_typographie.id_customization_field +
                          "]",
                        id:
                          "customizations_fields_" +
                          _vm.field_typographie.id_customization_field +
                          "_" +
                          value
                      },
                      domProps: {
                        value: value,
                        checked: _vm._q(
                          _vm.item[
                            "gravure_" + _vm.gravure_id + "_typographie"
                          ],
                          value
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.item,
                            "gravure_" + _vm.gravure_id + "_typographie",
                            value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for:
                            "customizations_fields_" +
                            _vm.field_typographie.id_customization_field +
                            "_" +
                            value
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.images_path + "/" + value + ".svg",
                            alt: ""
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(_vm._s(label))
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }