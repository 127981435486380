import $ from 'jquery';
import {
  TimelineLite,
  Power1,
} from 'gsap';
import ScrollMagic from 'scrollmagic';

export default class HomeElleLui {
  static init() {
    this.$section = $('.home-elle-lui');

    if (this.$section.length === 0) {
      return;
    }

    this.$elleLink = this.$section.find('.elle .link');
    this.$luiLink = this.$section.find('.lui .link');

    this.controller = new ScrollMagic.Controller();

    this.initApparitionOnScroll();
  }

  static initApparitionOnScroll() {
    const timeline = new TimelineLite({
      onComplete: () => {
        this.$section.addClass('links-visible');
      },
    });

    timeline
      .add(this.animateVolet(this.$elleLink), 0)
      .add(this.animateVolet(this.$luiLink, true), 0.3);

    new ScrollMagic.Scene({
      triggerElement: this.$section[0],
      triggerHook: 0.4,
      reverse: false,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static animateVolet($link, toRight = false) {
    const timeline = new TimelineLite();

    timeline
      .staggerFromTo($link.children(), 0.4, {
        y: (toRight ? -1 : 1) * 20,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        clearProps: 'all',
        ease: Power1.easeOut,
      }, 0.1);

    return timeline;
  }
}
