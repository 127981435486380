/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import $ from 'jquery';
import {
  TimelineLite,
  Power2,
  TweenLite,
  Power0,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/animation.gsap';
import { SplitText } from '../../vendor/gsap/SplitText';

export default class AnimationUtils {
  static animateHeightToZero($element, duration = 0.5) {
    if (!$element) {
      console.error('No element to animate');
    }

    $element.css('height', 'auto');
    const autoHeight = $element.height();
    TweenLite.fromTo($element, duration, {
      height: autoHeight,
    }, {
      height: 0,
      ease: Power2.easeInOut,
    });
  }

  static animateHeightToAuto($element, duration = 0.5, callback = () => {}) {
    if (!$element) {
      console.error('No element to animate');
    }

    const curHeight = $element.height();
    $element.css('height', 'auto');
    const autoHeight = $element.height();
    $element.css('height', curHeight);
    TweenLite.to($element, duration, {
      height: autoHeight,
      ease: Power2.easeInOut,
      onComplete: () => {
        $element.css('height', 'auto');
        callback();
      },
    });
  }

  static voletImageApparition(imageContainer, direction = 'right', voletSpeed = 0.8, imageSpeed = 0.8, callback) {
    const volet = $(imageContainer).find('.volet')[0];
    const image = $(imageContainer).find('.image')[0];

    if (!image || !volet) {
      console.error('No image or volet to animate', imageContainer);
      return false;
    }

    const timeline = new TimelineLite({
      onComplete: callback,
    });

    let startOrigin;
    let stopOrigin;
    let scaleDirection;
    let inverseScaleDirection;

    switch (direction) {
      case 'top':
        startOrigin = 'bottom center';
        stopOrigin = 'top center';
        scaleDirection = 'scaleY';
        inverseScaleDirection = 'scaleX';
        break;
      case 'right':
        startOrigin = 'center left';
        stopOrigin = 'center right';
        scaleDirection = 'scaleX';
        inverseScaleDirection = 'scaleY';
        break;
      case 'bottom':
        startOrigin = 'top center';
        stopOrigin = 'bottom center';
        scaleDirection = 'scaleY';
        inverseScaleDirection = 'scaleX';
        break;
      case 'left':
        startOrigin = 'center right';
        stopOrigin = 'center left';
        scaleDirection = 'scaleX';
        inverseScaleDirection = 'scaleY';
        break;
      default:
        break;
    }

    timeline
      .set(volet, {
        transformOrigin: startOrigin,
        [inverseScaleDirection]: 1,
      })
      .fromTo(volet, voletSpeed, {
        [scaleDirection]: 0,
      }, {
        [scaleDirection]: 1,
        ease: Power2.easeIn,
      })
      .fromTo(image, 0.001, { autoAlpha: 0 }, { autoAlpha: 1 })
      .set(volet, { transformOrigin: stopOrigin })
      .to(volet, imageSpeed, {
        [scaleDirection]: 0,
        ease: Power2.easeOut,
      });

    return timeline;
  }

  static voletImageSimpleApparition(imageContainer, direction = 'right', imageSpeed = 1, callback) {
    const volet = $(imageContainer).find('.volet')[0];
    const image = $(imageContainer).find('.image')[0];

    if (!image || !volet) {
      console.error('No image or volet to animate', imageContainer);
      return false;
    }

    const timeline = new TimelineLite({
      onComplete: callback,
    });

    let voletOrigin;
    let scaleDirection;
    let inverseScaleDirection;

    switch (direction) {
      case 'top':
        voletOrigin = 'top center';
        scaleDirection = 'scaleY';
        inverseScaleDirection = 'scaleX';
        break;
      case 'right':
        voletOrigin = 'center right';
        scaleDirection = 'scaleX';
        inverseScaleDirection = 'scaleY';
        break;
      case 'bottom':
        voletOrigin = 'bottom center';
        scaleDirection = 'scaleY';
        inverseScaleDirection = 'scaleX';
        break;
      case 'left':
        voletOrigin = 'center left';
        scaleDirection = 'scaleX';
        inverseScaleDirection = 'scaleY';
        break;
      default:
        break;
    }

    timeline
      .set(volet, {
        transformOrigin: voletOrigin,
        [inverseScaleDirection]: 1,
      })
      .fromTo(image, 0.001, { autoAlpha: 0 }, { autoAlpha: 1 })
      .fromTo(volet, imageSpeed, {
        [scaleDirection]: 1,
      }, {
        [scaleDirection]: 0,
        ease: Power2.easeInOut,
      });

    return timeline;
  }

  static innerImageParalax(scrollMagicController, imageContainer, decalage = 20, duration = '150%', triggerHook = 1) {
    const imageTimeline = new TimelineLite();

    const $image = $(imageContainer).find('.image');

    if (!imageContainer || $image.length === 0) {
      console.error('No image or imageContainer to animate', imageContainer);
      return;
    }

    TweenLite.set($image, {
      height: `${100 + decalage}%`,
    });
    TweenLite.set(imageContainer, {
      overflow: 'hidden',
    });

    imageTimeline
      .fromTo($image, 1, {
        y: `-${decalage}%`,
      }, {
        y: '0%',
        ease: Power0.easeNone,
      });

    new ScrollMagic.Scene({
      triggerElement: imageContainer,
      triggerHook,
      duration,
    })
      .setTween(imageTimeline)
      .addTo(scrollMagicController);
  }

  static textLineApparition(lineContainer, apparitionSpeed = 0.6, revert = true) {
    const $lineContainer = $(lineContainer);
    const lineContent = $lineContainer.html();
    $lineContainer.html('').append($('<span class="line-inner">').html(lineContent));
    const lineInner = $lineContainer.children('.line-inner')[0];

    const timeline = new TimelineLite({
      onComplete: () => {
        if (revert) {
          const html = $(lineInner).html();
          $lineContainer.html(html);
        }
      },
    });

    TweenLite.set(lineContainer, {
      overflow: 'hidden',
    });
    TweenLite.set(lineInner, {
      display: 'inline-block',
    });

    timeline
      .from(lineInner, apparitionSpeed, {
        y: '100%',
        ease: Power2.easeOut,
      })
      .set(lineContainer, {
        overflow: '',
      });

    return timeline;
  }

  static multipleTextLinesApparition(
    element,
    apparitionSpeed = 0.6,
    staggerDuration = 0.2,
    revert = true,
  ) {
    const split = new SplitText(element, {
      type: 'lines',
      linesClass: 'line',
    });

    const timeline = new TimelineLite({
      onComplete: () => {
        if (revert) {
          split.revert();
        }
      },
    });

    split.lines.forEach((line, index) => {
      timeline.add(this.textLineApparition(line, apparitionSpeed, revert), `start+=${index * staggerDuration}`);
    });

    return timeline;
  }

  static showVideoButton(button) {
    const timeline = new TimelineLite();

    const $button = $(button);
    const volet = $button.find('.volet')[0];

    timeline
      .set(volet, { transformOrigin: 'center right' })
      .to(volet, 0.5, {
        scaleX: 0,
        ease: Power2.easeOut,
      });

    return timeline;
  }
}
