import $ from 'jquery';
import AnimationUtils from './animation-utils';
import Utils from './utils';

export default class ReadMore {
  static initAll() {
    const $containers = $('.read-more-container');

    $containers.each((index, container) => {
      // eslint-disable-next-line no-unused-vars
      const readMore = new ReadMore(container);
    });
  }

  constructor(container) {
    this.$container = $(container);

    this.$link = this.$container.find('.read-more');

    if (Utils.getAutoHeight(this.$container[0]) < this.$container.height()) {
      this.$container.addClass('js-deployed');
      return;
    }

    const handleClick = () => {
      this.$container.addClass('js-deployed');
      AnimationUtils.animateHeightToAuto(this.$container);
      this.$link.off('click', handleClick);
    };

    this.$link.on('click', handleClick);
  }
}
