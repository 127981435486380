import $ from 'jquery';
import {
  TweenLite, Power2, TimelineLite, Power1,
} from 'gsap';
import Utils from '../utils/utils';

export default class ProductMiniature {
  static init() {
    this.$thumbnails = $('.products .thumbnail');

    if (this.$thumbnails.length === 0 || $('body').width() < Utils.breakpoints().lg) {
      return;
    }

    this.hoverTimeline = {};

    this.initMiniatureHover();
  }

  static initMiniatureHover() {
    this.$thumbnails.on('mouseenter', (event) => {
      this.onMouseEnter($(event.currentTarget));
    });

    this.$thumbnails.on('mouseleave', (event) => {
      this.onMouseLeave($(event.currentTarget));
    });

    this.$thumbnails.on('mousemove', (event) => {
      this.$animatedThumbnail = $(event.currentTarget);
      const elementOffest = this.$animatedThumbnail.offset();
      const halfHeight = this.$animatedThumbnail.height() / 2;
      const halfWidth = this.$animatedThumbnail.width() / 2;
      const x = (event.pageX - elementOffest.left) - halfWidth;
      const y = (event.pageY - elementOffest.top) - halfHeight;

      this.xPercent = Utils.roundNumber(x / halfWidth, 2);
      this.yPercent = Utils.roundNumber(y / halfHeight, 2);

      window.cancelAnimationFrame(this.raf);
      this.raf = window.requestAnimationFrame(() => this.updateAnimation());
    });
  }

  static onMouseEnter($thumbnail) {
    const id = this.$thumbnails.index($thumbnail);

    if (this.hoverTimeline[id] && this.hoverTimeline[id].progress() < 1) {
      this.hoverTimeline[id].pause();
    } else {
      TweenLite
        .set($thumbnail.find('.overlay'), {
          autoAlpha: 0,
        });
      TweenLite
        .set($thumbnail.find('.background-image'), {
          scale: 1.1,
        });
      TweenLite
        .set($thumbnail.find('.label'), {
          autoAlpha: 0,
        });
    }
    this.hoverTimeline[id] = new TimelineLite();

    const duration = 0.3;

    this.hoverTimeline[id]
      .to($thumbnail.find('.overlay'), duration, {
        autoAlpha: 1,
        ease: Power1.easeOut,
      })
      .to($thumbnail.find('.background-image'), duration, {
        scale: 1,
        ease: Power1.easeOut,
      }, 0)
      .to($thumbnail.find('.label'), duration, {
        autoAlpha: 1,
        ease: Power1.easeInOut,
      }, 0.1);
  }

  static onMouseLeave($thumbnail) {
    const id = this.$thumbnails.index($thumbnail);

    if (this.hoverTimeline[id] && this.hoverTimeline[id].progress() < 1) {
      this.hoverTimeline[id].pause();
    } else {
      TweenLite
        .set($thumbnail.find('.overlay'), {
          autoAlpha: 1,
        });
      TweenLite
        .set($thumbnail.find('.background-image'), {
          scale: 1,
        });
      TweenLite
        .set($thumbnail.find('.label'), {
          autoAlpha: 1,
        });
    }
    this.hoverTimeline[id] = new TimelineLite();

    const duration = 0.3;

    this.hoverTimeline[id]
      .to($thumbnail.find('.background-image'), duration, {
        scale: 1.1,
        ease: Power1.easeIn,
      }, 0)
      .to($thumbnail.find('.label'), duration, {
        autoAlpha: 0,
        ease: Power1.easeInOut,
      }, 0)
      .to($thumbnail.find('.overlay'), duration - 0.05, {
        autoAlpha: 0,
        ease: Power1.easeIn,
      }, 0.05);
  }

  static updateAnimation() {
    TweenLite.to(this.$animatedThumbnail.find('.label'), 0.7, {
      x: this.xPercent * 5,
      y: this.yPercent * 5,
      ease: Power2.easeOut,
    });

    TweenLite.to(this.$animatedThumbnail.find('.background-image'), 0.7, {
      x: this.xPercent * 2,
      y: this.yPercent * 2,
      ease: Power2.easeOut,
    });
  }
}
