import $ from 'jquery';
import ProductMiniature from './product-miniature';
import InnerImageParalax from '../utils/inner-image-paralax';
import LazyLoad from '../utils/lazyload';

export default class ProductsList {
  static init() {
    this.$section = $('#js-product-list');

    if (this.$section.length === 0) {
      return;
    }

    this.$productsContainer = this.$section.find('.products');
    this.$products = this.$productsContainer.find('.js-product-miniature');
    this.$illustrations = this.$productsContainer.find('.image-illustration');

    ProductMiniature.init();
    InnerImageParalax.init();

    this.hoverTimeline = {};
    LazyLoad.checkElements();

    this.hideExtraIllustrations();
  }

  static hideExtraIllustrations() {
    const productCount = this.$products.length;

    const maxProductFor3Images = 14;

    const base = Math.floor(productCount / maxProductFor3Images);
    const modulo = productCount % maxProductFor3Images;
    let sum = 0;

    if (modulo >= 3) {
      sum += 1;
    }

    if (modulo >= 9) {
      sum += 1;
    }

    if (modulo >= 13) {
      sum += 1;
    }

    const maxImage = base * 3 + sum;

    if (maxImage === 0) {
      this.$illustrations.addClass('hidden');
    } else {
      this.$illustrations.filter(`:gt(${maxImage - 1})`).addClass('hidden');
      this.$illustrations.filter(`:lt(${maxImage - 1})`).removeClass('hidden');
    }
  }
}
