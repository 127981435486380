var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showErrorMessage
    ? _c(
        "div",
        { staticClass: "error-container" },
        _vm._l(_vm.errorMessages, function(messageId) {
          return _c("p", {
            key: messageId,
            staticClass: "error-message",
            domProps: { innerHTML: _vm._s(_vm.wordings.errors[messageId]) }
          })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }