import $ from 'jquery';
import {
  TimelineLite,
  Power0,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import AnimationUtils from '../utils/animation-utils';

export default class PageBuilder {
  static init() {
    if (!$('body').hasClass('-builder')) {
      return;
    }

    this.$page = $('.main-content');

    this.controller = new ScrollMagic.Controller();

    this.initIntroParalax();

    if (this.$page.find('.section-text-image-big .image-column').length > 0) {
      this.initImageApparition(this.$page.find('.section-text-image-big .image-column'));
    }

    if (this.$page.find('.section-text-image .image-column').length > 0) {
      this.initImageApparition(this.$page.find('.section-text-image .image-column'));
    }
  }

  static initIntroParalax() {
    AnimationUtils.innerImageParalax(this.controller, this.$page.find('.intro-full-image')[0]);
  }

  static initImageApparition($imageColumn) {
    const timeline = new TimelineLite();

    const $imageContainer = $imageColumn.find('.image-container');
    const $imageLegend = $imageColumn.find('.image-legend');

    timeline
      .add(AnimationUtils.voletImageSimpleApparition($imageContainer, 'bottom'), 0);

    if ($imageLegend.length > 0) {
      timeline
        .fromTo($imageLegend, 0.4, {
          autoAlpha: 0,
          y: 10,
        }, {
          autoAlpha: 1,
          y: 0,
        }, '-=0.3');
    }

    new ScrollMagic.Scene({
      triggerElement: $imageColumn[0],
      triggerHook: 0.7,
      reverse: false,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initImagesParalax($imageColumn, reverse = false) {
    const timeline = new TimelineLite();

    const $imageContainer = $imageColumn.find('.image-paralax .image');

    const paralaxRatio = 50;

    const mult = reverse ? -1 : 1;

    timeline
      .fromTo($imageContainer, 1, {
        y: -mult * paralaxRatio,
      }, {
        y: mult * paralaxRatio,
        ease: Power0.easeNone,
      }, 0);

    new ScrollMagic.Scene({
      triggerElement: $imageColumn[0],
      triggerHook: 1,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }
}
