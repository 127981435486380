var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wishlist-btn" }, [
    _c(
      "a",
      {
        attrs: { title: _vm.wordings.button_title },
        on: { click: _vm.action }
      },
      [
        _c("i", {
          staticClass: "icon",
          class: {
            "icon-heart": !_vm.product_in_wishlist,
            "icon-heart-fill": _vm.product_in_wishlist
          }
        })
      ]
    ),
    _vm._v(" "),
    _vm.isMessageVisible
      ? _c("p", { staticClass: "message" }, [
          _vm._v("\n        " + _vm._s(_vm.message) + "\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }