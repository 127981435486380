<template>
    <form class="gravure-form form">
        <div v-if="field_message" class="form-group inline-form-group">
            <label class="form-control-label">{{ field_message.label }}</label>

            <div class="count-input">
                <input
                    class="input"
                    type="text"
                    :name="`customizations_fields[${field_message.id_customization_field}]`"
                    :placeholder="placeholders.message"
                    :maxlength="field_message.maxlength"
                    v-model="item[`gravure_${gravure_id}_message`]"
                />
                <span class="count">{{item[`gravure_${gravure_id}_message`] ? item[`gravure_${gravure_id}_message`].length : 0}} / {{ char_max }}</span>
            </div>
        </div>

        <div v-if="field_emplacement" class="form-group inline-form-group emplacements-group">
            <label class="form-control-label">{{ field_emplacement.label }}</label>
            <ul class="radios-container emplacements-container">
                <li v-for="emplacement in field_emplacement.values" :key="emplacement.id_emplacement" class="radio-container">
                    <input
                        type="radio"
                        :name="`customizations_fields[${field_emplacement.id_customization_field}]`"
                        :id="`customizations_fields_${field_emplacement.id_customization_field}_${emplacement.id_emplacement}`"
                        v-model="item[`gravure_${gravure_id}_emplacement`]"
                        :value="emplacement.id_emplacement"
                        :disabled="gravure_id === 2 && item.gravure_1_emplacement === emplacement.id_emplacement"
                    />
                    <label
                        :for="`customizations_fields_${field_emplacement.id_customization_field}_${emplacement.id_emplacement}`"
                        class="control-label"
                    >
                        <img :src="`${images_path}/${emplacement.image}`" alt="">
                    {{ emplacement.title }}</label>
                </li>
            </ul>
        </div>

        <div v-if="field_type" class="form-group inline-form-group">
            <label class="form-control-label">{{ field_type.label }}</label>
            <ul class="radios-container">
                <li v-for="(label, value) in field_type.values" :key="value" class="radio-container">
                    <input type="radio"
                        :name="`customizations_fields[${field_type.id_customization_field}]`"
                        :id="`customizations_fields_${field_type.id_customization_field}_${value}`"
                        v-model="item[`gravure_${gravure_id}_type`]"
                        :value="value"
                    />
                    <label
                        :for="`customizations_fields_${field_type.id_customization_field}_${value}`"
                        class="control-label"
                    >{{ label }}</label>
                </li>
            </ul>
        </div>

        <div v-if="field_typographie" class="form-group inline-form-group typographie-radios">
            <label class="form-control-label">{{ field_typographie.label }}</label>
            <ul class="radios-container">
                <li v-for="(label, value) in field_typographie.values" :key="value" class="radio-container">
                    <input
                        type="radio"
                        :name="`customizations_fields[${field_typographie.id_customization_field}]`"
                        :id="`customizations_fields_${field_typographie.id_customization_field}_${value}`"
                        v-model="item[`gravure_${gravure_id}_typographie`]"
                        :value="value"
                    />
                    <label
                        :for="`customizations_fields_${field_typographie.id_customization_field}_${value}`"
                        class="control-label"
                    >
                        <img :src="`${images_path}/${value}.svg`" alt="">
                        <span class="sr-only">{{ label }}</span>
                    </label>
                </li>
            </ul>
        </div>
    </form>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        props: {
            customizations_fields: {
                type: Object,
            },
            item: {
                type: Object,
            },
            placeholders: {
                type: Object,
            },
            gravure_id: {
                type: Number,
            },
            images_path: {
                type: String,
            }
        },
        data() {
            return {
                char_max: 10,
                char_count: 0
            }
        },
        watch: {

        },
        computed: {
            field_message() {
                return this.customizations_fields[`gravure_${this.gravure_id}_message`] ? this.customizations_fields[`gravure_${this.gravure_id}_message`] : null;
            },
            field_emplacement() {
                return this.customizations_fields[`gravure_${this.gravure_id}_emplacement`] ? this.customizations_fields[`gravure_${this.gravure_id}_emplacement`] : null;
            },
            field_type() {
                return this.customizations_fields[`gravure_${this.gravure_id}_type`] ? this.customizations_fields[`gravure_${this.gravure_id}_type`] : null;
            },
            field_typographie() {
                return this.customizations_fields[`gravure_${this.gravure_id}_typographie`] ? this.customizations_fields[`gravure_${this.gravure_id}_typographie`] : null;
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>
