import $ from 'jquery';
import { TimelineLite, Power2 } from 'gsap';
import AnimationUtils from '../utils/animation-utils';
import Utils from '../utils/utils';

export default class AdvancedSearch {
  static init() {
    this.initInteractions();

    $(document).on('as4-After-Set-Results-Contents', () => this.initInteractions());
  }

  static initInteractions() {
    this.$container = $('.PM_ASBlockOutput');

    if (this.$container.length === 0) {
      return;
    }

    this.$advancedSearchLabels = this.$container.find('.criterion-label');
    this.$mobileButton = this.$container.find('.search-mobile-button');
    this.$searchBlock = this.$container.find('.PM_ASBlock');
    this.$mobileClose = this.$searchBlock.find('.search-header .close');

    this.$advancedSearchLabels.on('click', (event) => {
      const $criterionContainer = $(event.currentTarget).parent('.PM_ASCriterions');
      this.toggleCriterion($criterionContainer);
    });

    if ($('body').width() < Utils.breakpoints().lg) {
      this.$mobileButton.on('click', () => this.openMobileModal());
      this.$mobileClose.on('click', () => this.closeMobileModal());

      $(document).on('as4-Criterion-Change', () => this.closeMobileModal());
    }
  }

  static toggleCriterion($criterionContainer) {
    if ($criterionContainer.hasClass('js-opened')) {
      this.closeCriterion($criterionContainer);
    } else {
      this.openCriterion($criterionContainer);
    }
  }

  static openCriterion($criterionContainer) {
    const $criterions = $criterionContainer.find('.criterion-container');
    AnimationUtils.animateHeightToAuto($criterions, 0.4);
    $criterionContainer.addClass('js-opened');
  }

  static closeCriterion($criterionContainer) {
    const $criterions = $criterionContainer.find('.criterion-container');
    AnimationUtils.animateHeightToZero($criterions, 0.4);
    $criterionContainer.removeClass('js-opened');
  }

  static openMobileModal() {
    const timeline = new TimelineLite();

    timeline
      .set(this.$searchBlock, {
        display: 'block',
      })
      .fromTo(this.$searchBlock, 0.5, {
        x: '100%',
      }, {
        x: '0%',
        ease: Power2.easeOut,
      });
  }

  static closeMobileModal() {
    const timeline = new TimelineLite();

    timeline
      .fromTo(this.$searchBlock, 0.5, {
        x: '0%',
      }, {
        x: '100%',
        ease: Power2.easeInOut,
      })
      .set(this.$searchBlock, {
        display: 'none',
      });
  }
}
