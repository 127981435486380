import $ from 'jquery';
import {
  TimelineLite,
  Power1,
  Linear,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import Utils from '../utils/utils';

export default class HomeIntro {
  static init() {
    this.$section = $('.home-intro');

    if (this.$section.length === 0) {
      return;
    }

    this.controller = new ScrollMagic.Controller();

    this.initScrollAnimations();
  }

  static initScrollAnimations() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }

    const $content = this.$section.find('.content');
    const $mainImage = this.$section.find('.main-image');

    const timeline = new TimelineLite();

    timeline
      .to($mainImage, 1, {
        y: 60,
        ease: Linear.easeNone,
      }, 0)
      .to($content, 1, {
        y: 30,
        ease: Linear.easeNone,
      }, 0);

    new ScrollMagic.Scene({
      triggerHook: 1,
      duration: '100%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static animateVolet($link, toRight = false) {
    const timeline = new TimelineLite();

    timeline
      .staggerFromTo($link.children(), 0.4, {
        y: (toRight ? -1 : 1) * 20,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        ease: Power1.easeOut,
      }, 0.1);

    return timeline;
  }
}
