export default class ModalStore {
  static init() {
    if (this.isInit) {
      return;
    }
    this.isInit = true;
    this.modals = {};
  }

  static add(id, modal) {
    this.init();

    this.modals[id] = modal;
  }

  static exists(id) {
    this.init();

    return !!this.modals[id];
  }

  static get(id) {
    this.init();

    return this.modals[id];
  }
}
