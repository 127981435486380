import $ from 'jquery';

export default class Form {
  static init() {
    this.togglePasswordVisibility();
  }

  static togglePasswordVisibility() {
    $('button[data-action="show-password"]').on('click', (event) => {
      const $button = $(event.currentTarget);
      const $element = $button.siblings('input');
      let iconClass;

      if ($element.attr('type') === 'password') {
        $element.attr('type', 'text');
        iconClass = $button.data('icon-hide');
      } else {
        $element.attr('type', 'password');
        iconClass = $button.data('icon-show');
      }

      const $newIcon = $('<i class="icon">');
      $newIcon.addClass(`icon-${iconClass}`);
      $button.html($newIcon);
    });
  }
}
