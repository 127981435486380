import $ from 'jquery';
import '../font-icons/font-icons.font';
import '../scss/theme.scss';
import './components/block-cart';
import ModalOpener from './transversal/modal-opener';
import SearchBar from './components/searchbar';
import StickyMenu from './utils/sticky-menu';
import PageBoutiques from './pages/page-boutiques';
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import 'imports-loader?define=>false!scrollmagic/animation.gsap';
// import 'imports-loader?define=>false!scrollmagic/debug.addIndicators';
/* eslint-enable import/no-webpack-loader-syntax */
/* eslint-enable import/no-unresolved */

import FAQ from './components/faq';
import Form from './components/form';
import Tabs from './utils/tabs';
import Sticky from './transversal/sticky';
import PageHistoire from './pages/page-histoire';
import PageBuilder from './pages/page-builder';
import InnerImageParalax from './utils/inner-image-paralax';
import PageSavoirFaire from './pages/page-savoir-faire';
import MobileMenu from './utils/mobile-menu';
import SelectToLink from './utils/select-to-link';
import './responsive';
import './listing';
import './product';
import './cart';
// import './checkout';

import './mdeloche';
import PageCollection from './pages/page-collection';
import PageProduct from './pages/page-product';
import RecentlyViewed from './components/block-recently-viewed';
import ProductsList from './components/products-list';
import ProductMiniature from './components/product-miniature';
import HomeInstagram from './components/home-instagram';
import HomeElleLui from './components/home-elle-lui';
import HomeSemainier from './components/home-semainier';
import HomeIntro from './components/home-intro';
import AdvancedSearch from './components/advanced-search';
import ConseilsMenu from './components/conseils-menu';
import FooterMenuMonbile from './components/footer-menu-mobile';
import ToggleBlock from './transversal/toggle-block';

import TermsTargetBlank from './components/cta-terms-and-conditions';
import TouchDevice from './transversal/touch-device';
import CookiesBanner from './utils/cookies-banner';
import BandeauPromo from './components/bandeau-promo';
import LazyLoad from './utils/lazyload';
import ReadMore from './utils/read-more';

$(document).ready(() => {
  LazyLoad.init();
  TouchDevice.init();
  ModalOpener.init();
  ToggleBlock.init();
  SearchBar.init();
  CookiesBanner.init();
  BandeauPromo.init();
  Form.init();
  Tabs.init();
  Sticky.init();
  InnerImageParalax.init();
  MobileMenu.init();
  SelectToLink.init();
  RecentlyViewed.init();
  ProductMiniature.init();
  HomeInstagram.init();
  HomeElleLui.init();
  HomeSemainier.init();
  HomeIntro.init();
  AdvancedSearch.init();
  ConseilsMenu.init();
  FooterMenuMonbile.init();
  ReadMore.initAll();

  // eslint-disable-next-line no-unused-vars
  const menu = new StickyMenu($('.main-header'));
  document.querySelector('body').addEventListener('page-cache-ultimate-loaded', () => {
    InnerImageParalax.init();
    HomeInstagram.init();
    HomeElleLui.init();
    HomeSemainier.init();
    HomeIntro.init();
  });

  PageBoutiques.init();
  PageHistoire.init();
  PageBuilder.init();
  PageSavoirFaire.init();
  FAQ.init();
  PageCollection.init();
  PageProduct.init();
  ProductsList.init();

  TermsTargetBlank.init();
});
