import $ from 'jquery';
import {
  TimelineLite,
} from 'gsap';

export default class Tabs {
  static init() {
    this.$tabLinks = $('[data-toggle="tab"]');

    if (this.$tabLinks.length === 0) {
      return;
    }

    this.$tabLinks.on('click', (event) => {
      event.preventDefault();
      const $link = $(event.currentTarget);

      if ($link.hasClass('active')) {
        return;
      }

      $link.siblings().removeClass('active');
      $link.addClass('active');

      const $nextTab = $($link.attr('href'));
      const $tabContainer = $nextTab.parent('.tab-content');
      const $previousTab = $tabContainer.find('.tab-pane.active');

      // $tabContainer.css('height', $previousTab.height());
      $nextTab.css('display', 'block');
      const nextHeight = $nextTab.height();
      $nextTab.css('display', '');

      const previousHeight = $previousTab.height();

      if (this.timeline) {
        this.timeline.progress(1);
        this.timeline.kill();
      }

      this.timeline = new TimelineLite();

      $previousTab.removeClass('active');
      $nextTab.addClass('active');

      this.timeline
        .set($previousTab, {
          display: 'none',
        })
        .fromTo($tabContainer, 0.4, {
          height: previousHeight,
        }, {
          height: nextHeight,
        }, 0)
        .fromTo($nextTab, 0.3, {
          autoAlpha: 0,
        }, {
          autoAlpha: 1,
        }, '-=0.3');
    });
  }
}
