import $ from 'jquery';
import 'slick-carousel';
import Utils from '../utils/utils';

export default class RecentlyViewed {
  static init() {
    this.$section = $('.block-recently-viewed');

    if (this.$section.length === 0) {
      return;
    }

    this.$carouselContainer = this.$section.find('.carousel');

    this.$carouselContainer.slick({
      // dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 6,
      slidesToScroll: 1,
      nextArrow: '<div class="next-arrow"><i class="icon icon-arrow-right"></i></div>',
      prevArrow: '<div class="prev-arrow"><i class="icon icon-arrow-left"></i></div>',
      responsive: [
        {
          breakpoint: Utils.breakpoints().md,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });
  }
}
