import axios from 'axios';

const EmailSubscription = {
  send(formData) {
    return axios({
      method: 'post',
      url: `${prestashop.urls.base_url}modules/md_emailsubscription/ajax_subscribe.php`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then(response => Promise.resolve(response.data))
      .catch(error => console.log(error));
  },
};

export default EmailSubscription;
