import StickyJS from 'sticky-js';
import Utils from '../utils/utils';

export default class Sticky {
  static init() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const sticky = new StickyJS('[data-sticky]');
  }
}
