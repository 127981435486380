import $ from 'jquery';
import AnimationUtils from '../utils/animation-utils';

export default class ToggleBlock {
  static init() {
    this.$togglers = $('[data-toggle]:not([data-toggle="tab"])');

    this.$togglers.on('click', (event) => {
      event.preventDefault();
      const blockId = $(event.currentTarget).attr('href');

      const $block = $(blockId);

      if ($block.hasClass('in')) {
        AnimationUtils.animateHeightToZero($block);
      } else {
        AnimationUtils.animateHeightToAuto($block, 0.5, () => {
          $block.find('input:text:visible:first').first().focus();
        });
      }

      $block.toggleClass('in');
    });
  }
}
