import $ from 'jquery';
import {
  TimelineLite,
  Power2,
} from 'gsap';
import Hammer from 'hammerjs';

export default class ProductGallery {
  constructor($galleryContainer) {
    if (!$galleryContainer || $galleryContainer.length === 0) {
      console.error('No gallery element');
      return;
    }

    this.$galleryContainer = $galleryContainer;

    this.$navItems = this.$galleryContainer.find('.js-gallery-nav-item');
    this.$galleryItems = this.$galleryContainer.find('.js-gallery-item');

    if (this.$galleryItems.length === 0) {
      console.error('No gallery element');
      return;
    }

    this.currentId = this.$navItems.filter('.selected').data('gallery-item');

    this.$prevArrow = this.$galleryContainer.find('.arrow-prev');
    this.$nextArrow = this.$galleryContainer.find('.arrow-next');

    this.$navItems.on('mouseenter', (event) => {
      const $clickedItem = $(event.currentTarget);

      this.onNavItemClick($clickedItem);
    });

    this.$prevArrow.on('click', () => {
      this.showPrevItem();
    });

    this.$nextArrow.on('click', () => {
      this.showNextItem();
    });

    const hammertime = new Hammer($galleryContainer[0]);

    hammertime.on('swipeleft', () => {
      this.showNextItem();
    });

    hammertime.on('swiperight', () => {
      this.showPrevItem();
    });
  }

  onNavItemClick($navItem) {
    const itemId = $navItem.data('gallery-item');

    this.showItem(itemId);
  }

  showPrevItem() {
    let $prevNavItem = this.$navItems.filter('.selected').prev();

    if ($prevNavItem.length === 0) {
      $prevNavItem = this.$navItems.last();
    }

    const prevItemId = $prevNavItem.data('gallery-item');

    this.showItem(prevItemId);
  }

  showNextItem() {
    let $nextNavItem = this.$navItems.filter('.selected').next();

    if ($nextNavItem.length === 0) {
      $nextNavItem = this.$navItems.first();
    }

    const nextItemId = $nextNavItem.data('gallery-item');

    this.showItem(nextItemId, true);
  }

  showItem(itemId, animateToRight = false) {
    if (!itemId || this.currentId === itemId) {
      return;
    }

    const $previousItem = this.$galleryItems.filter('.current');
    const $nextItem = this.$galleryItems.filter(`.gallery-item-${itemId}`);

    if ($nextItem.length === 0) {
      console.error('No gallery item with id : ', itemId);
      return;
    }

    this.currentId = itemId;

    this.$navItems.filter('.selected').removeClass('selected');
    this.$navItems.filter(`[data-gallery-item=${this.currentId}]`).addClass('selected');

    $previousItem.removeClass('current');
    $nextItem.addClass('current');

    if (this.timeline) {
      this.timeline.progress(1, false);
    }

    this.timeline = new TimelineLite();
    const duration = 0.5;
    const mouvementRatio = animateToRight ? -1 : 1;

    this.timeline
      .set(this.$galleryItems, {
        zIndex: 0,
      })
      .set($nextItem.first(), {
        zIndex: 5,
        display: 'block',
      });

    if ($previousItem.length > 0) {
      this.timeline
        .set($previousItem, {
          zIndex: 4,
        })
        .fromTo($previousItem, duration, {
          x: '0%',
        }, {
          x: `${mouvementRatio * 50}%`,
          ease: Power2.easeInOut,
        }, 0);
    }

    this.timeline
      .fromTo($nextItem, duration, {
        x: `${-mouvementRatio * 100}%`,
      }, {
        x: '0%',
        ease: Power2.easeInOut,
      }, 0);

    if ($previousItem.length > 0) {
      this.timeline
        .set($previousItem, {
          zIndex: 0,
          display: 'none',
        });
    }
  }
}
