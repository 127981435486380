<template>
    <div class="wishlist-btn">
        <a @click="action" :title="wordings.button_title">
            <i class="icon" :class="{
                'icon-heart': !product_in_wishlist,
                'icon-heart-fill': product_in_wishlist
            }"></i>
        </a>

        <p class="message" v-if="isMessageVisible">
            {{ message }}
        </p>
    </div>
</template>

<script>
    import Utils from './../../../services/Utils.js';
    import uniq from 'lodash/uniq';
    import remove from 'lodash/remove';
    import axios from 'axios';

    export default {
        props: {
            url_api: String,
            in_wishlist: String,
            wordings: Object,
            id_product: String,
            is_list_page: Boolean,
        },
        data() {
            return {
                loading: false,
                isMessageVisible: false,
                message: null,
                product_in_wishlist: this.in_wishlist
            }
        },
        mounted() {
        },
        methods: {
            action() {
                if(this.product_in_wishlist)
                    this.remove();
                else
                    this.add();
            },
            add() {
                if(!this.id_product)
                    return;

                this.loading = true;

                if(!prestashop.customer.is_logged) {
                    this.addInCookie();
                    return false;
                }

                let data = {
                    action: "add",
                    id_product: this.id_product
                };

                axios({
                    method: 'post',
                    url: this.url_api,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                }).then((response) => {
                    this.addSuccess();
                }).catch((error) => {
                    this.errorShow();
                });
            },
            remove() {
                let id_product = $('#product_page_product_id').val();

                this.loading = true;

                if(!prestashop.customer.is_logged) {
                    this.removeFromCookie(id_product);
                    return false;
                }

                let data = {
                    action: "remove",
                    id_product: this.id_product
                };

                axios({
                    method: 'post',
                    url: this.url_api,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                }).then((response) => {
                    this.removeSuccess();
                }).catch((error) => {
                    this.errorShow();
                });
            },
            addInCookie() {
                let items = Utils.getCookie('wishlist_collection');

                if(items) {
                    items = JSON.parse(items);
                } else {
                    items = [];
                }

                items.push(this.id_product);

                items = uniq(items);
                items = JSON.stringify(items);

                Utils.setCookie('wishlist_collection', items, 7);

                this.addSuccess();
            },
            removeFromCookie() {
                let items = Utils.getCookie('wishlist_collection');

                if(items) {
                    items = JSON.parse(items);
                } else {
                    items = [];
                }

                remove(items, (n) => {
                    return n == this.id_product;
                });

                items = JSON.stringify(items);

                Utils.setCookie('wishlist_collection', items, 7);

                this.removeSuccess();
            },
            removeSuccess() {
                this.product_in_wishlist = 0;
                this.showMessage(this.wordings.messages.success_remove);
                this.loading = false;

                if (this.is_list_page)
                    this.refresh();
            },
            addSuccess() {
                this.product_in_wishlist = 1;
                this.showMessage(this.wordings.messages.success_add);
                this.loading = false;
            },
            errorShow() {
                this.showMessage(this.wordings.messages.unknown);
                this.loading = false;
            },
            showMessage(message) {
                this.message = message;
                this.isMessageVisible = true;
                clearTimeout(this.messageTimeout);
                this.messageTimeout = setTimeout(() => {
                    this.hideMessage();
                }, 3000);
            },
            hideMessage() {
                this.isMessageVisible = false;
            },
            refresh() {
                window.location.reload();
            }
        }
    }
</script>
