import u from 'jquery';
import Utils from './utils';

export default class StickyMenu {
  constructor($header) {
    this.$header = $header;
    this.$body = u('body');

    this.lastScrollTop = 0;
    this.isMenuHidden = this.$header.hasClass('hidden-header');

    u(document).on('scroll', () => this.onScroll());
    this.onScroll();
  }

  onScroll() {
    if (this.$body.hasClass('block-scroll')) {
      return;
    }
    const st = Utils.getScrollAmount();

    if (st < 20) {
      this.$header.removeClass('not-on-top');
    } else {
      this.$header.addClass('not-on-top');
    }

    if (st - this.lastScrollTop > 30) {
      this.hideHeader();
    }

    if (st < this.lastScrollTop) {
      this.showHeader();
    }

    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  hideHeader() {
    if (this.isMenuHidden === true) {
      return;
    }

    this.$header.addClass('hidden-header');
    this.isMenuHidden = true;
  }

  showHeader() {
    if (this.isMenuHidden === false) {
      return;
    }

    this.$header.removeClass('hidden-header');
    this.isMenuHidden = false;
  }
}
