import { FormData } from 'formdata-node';
import Vue from 'vue';
import $ from 'jquery';
import ModalOpener from './transversal/modal-opener';
import Utils from './vuejs/services/Utils';
import EmailSubscription from './utils/email-subscription';

import ProductAttributeSelection from './vuejs/components/Product/AttributeSelection.vue';
import ProductGravure from './vuejs/components/Product/Gravure/Index.vue';
import ProductGravureModal from './vuejs/components/Product/Gravure/GravureModal.vue';
import ProductGravureForm from './vuejs/components/Product/Gravure/FormGravure.vue';
import ProductGiftcard from './vuejs/components/Giftcard/IndexMessage.vue';
import ProductGiftcardFormModal from './vuejs/components/Giftcard/FormModal.vue';
import ProductGiftcardReception from './vuejs/components/Giftcard/IndexReception.vue';
import ProductGiftcardErrors from './vuejs/components/Giftcard/Errors.vue';
import ProductWishlistButton from './vuejs/components/Product/Wishlist/ProductButton.vue';

prestashop.on('updateProduct', () => {
  // add loading for all updateProduct events
  Utils.showLoaderBody();
});

prestashop.on('updatedProduct', () => {
  ModalOpener.init();
  // remove loading for all updatedProduct events
  Utils.hideLoaderBody();
});

Vue.component('md-product-attribute-selection', ProductAttributeSelection);
Vue.component('md-product-gravure', ProductGravure);
Vue.component('md-product-gravure-modal', ProductGravureModal);
Vue.component('md-product-gravure-form', ProductGravureForm);

Vue.component('md-product-giftcard-message', ProductGiftcard);
Vue.component('md-product-giftcard-modal', ProductGiftcardFormModal);
Vue.component('md-product-giftcard-reception', ProductGiftcardReception);
Vue.component('md-product-giftcard-errors', ProductGiftcardErrors);

Vue.component('md-product-wishlist-button', ProductWishlistButton);

Vue.prototype.$eventBus = new Vue();

const app = new Vue({
  data() {
    return {

    };
  },
  computed: {
    id_product() {
      return $('#product_page_product_id').val();
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
});

// On monte la vue uniquement sur les pages nécessaires.
if (document.querySelector('body#product .main-content')) {
  app.$mount('body#product .main-content main');
}

if (document.querySelector('body#module-md_wishlist-list .main-content')) {
  app.$mount('body#module-md_wishlist-list .main-content .section-customer');
}

if (document.querySelector('body#category .main-content')) {
  app.$mount('body#category .main-content #products');
}

if (document.querySelector('.page-cms #products')) {
  app.$mount('#products');
}

if (document.querySelector('body#index .main-content .featured-products')) {
  app.$mount('body#index .main-content .featured-products');
}

$(document).ready(() => {
  // email subscription
  $('.block_newsletter form').submit((event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.set('email', $('input#newsletter-email').val());
    formData.set('action', 0);
    EmailSubscription.send(formData).then((response) => {
      $('.block_newsletter form p.notification').remove();
      let classNotification;
      if (response.error) {
        classNotification = 'notification-error';
      } else {
        classNotification = 'notification-success';
      }
      const elP = $('<p>')
        .addClass(`notification ${classNotification}`)
        .text(response.message);
      $('.block_newsletter form').append(elP);
    });
  });
});
