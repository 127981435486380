<template>
    <div class="gravure-modal form-modal" :class="{loading}">
        <div class="loader" v-if="loading"></div>
        <p class="modal-title"><slot name="title"></slot> <span class="small">(+{{ price_gravure }} {{ currency.sign }})</span></p>

        <div class="rte-content intro">
            <slot name="desc"></slot>
        </div>

        <p
            class="gravure-title"
            :class="{current: currentGravureEdition === 1}"
            v-if="hasMultipleGravure"
        >
            1. {{wordings.engraving}} {{item.gravure_1_message ? `« ${item.gravure_1_message} »` : ''}}
            <a href="#" @click="showFormGravure(1)" class="update-link">—  {{wordings.update}}</a>
        </p>

        <md-product-gravure-form
            ref="gravure_form_1"
            v-show="currentGravureEdition === 1"
            :gravure_id="1"
            :customizations_fields="customizations_fields"
            :placeholders="placeholders"
            :item="item"
            :images_path="images_path"
        ></md-product-gravure-form>

        <p
            class="gravure-title"
            :class="{current: currentGravureEdition === 2}"
            v-if="hasMultipleGravure"
        >
            2. {{wordings.engraving}} {{item.gravure_2_message ? `« ${item.gravure_2_message} »` : ''}}
            <a href="#" @click="removeSecondGravure()" class="remove-link">—  {{wordings.delete}}</a>
            <a href="#" @click="showFormGravure(2)" class="update-link">—  {{wordings.update}}</a>
        </p>

        <md-product-gravure-form
            ref="gravure_form_2"
            v-show="currentGravureEdition === 2"
            :gravure_id="2"
            :customizations_fields="customizations_fields"
            :placeholders="placeholders"
            :item="item"
            :images_path="images_path"
        ></md-product-gravure-form>

        <button type="button"
            class="add-second-gravure"
            @click="addSecondGravure"
            :disabled="!isGravure1Valid"
            v-show="canHaveMultipleGravure && !hasMultipleGravure">
            <i class="icon icon-gravure plus"></i>
            <span><slot name="text-add-gravure-2"></slot> (+{{ price_gravure }} {{ currency.sign }})</span>
        </button>

        <div class="form-group checkbox-container top-align checkbox-mentions">
            <input type="checkbox" name="mentions" id="mentions" value="1" v-model="item.mentions" />
            <label for="mentions" class="control-label"><slot name="mentions"></slot></label>
        </div>

        <div class="modal-footer">
            <button class="btn" type="button" @click="validate">
              <span class="btn-content">
                {{wordings.validate}}
                <i class="icon icon-check"></i>
              </span>
            </button>
            <button class="btn btn-red" type="button" @click="cancelCustomizations">
              <span class="btn-content">
                {{wordings.reset}}
                <i class="icon icon-cross"></i>
              </span>
            </button>
        </div>

        <div class="error-container" v-if="showErrorMessage">
            <p class="error-message" v-for="messageId in errorMessages" :key="messageId">{{wordings.errors[messageId]}}</p>
        </div>
    </div>
</template>

<script>
    import ModalStore from '../../../../utils/modal-store.js';
    import axios from 'axios';

    export default {
        props: {
            price_gravure: {
                type: Number
            },
            customizations_fields: {
                type: Object,
            },
            currency: {
                type: Object,
            },
            placeholders: {
                type: Object,
            },
            images_path: {
                type: String,
            },
            wordings: {
                type: Object,
            },
        },
        data() {
            return {
                isGravure1Valid: false,
                isGravure2Valid: false,
                currentGravureEdition: 1,
                hasMultipleGravure: false,
                showErrorMessage: false,
                errorMessages: [],
                loading: false,
                item: {
                    quantity: 1,
                    id_product: null,
                    id_product_attribute: null,
                    gravure_1: null,
                    gravure_1_message: null,
                    gravure_1_emplacement: null,
                    gravure_1_type: null,
                    gravure_1_typographie: null,
                    gravure_2: null,
                    gravure_2_message: null,
                    gravure_2_emplacement: null,
                    gravure_2_type: null,
                    gravure_2_typographie: null,
                    mentions: null
                }
            }
        },
        computed: {
            canHaveMultipleGravure() {
                return !!this.customizations_fields.gravure_2;
            },
        },
        watch: {
            item: {
                handler: function(after, before) {
                    const fields = [
                        'message',
                        'emplacement',
                        'type',
                        'typographie',
                    ];

                    const validator = (id) => {
                        return fields.reduce((isValid, field) => {
                            const fieldId = `${id}_${field}`;

                            if (this.customizations_fields[fieldId]) {
                                return isValid && !!after[fieldId];
                            } else {
                                return isValid;
                            }
                        }, true);
                    }

                    this.isGravure1Valid = validator('gravure_1');

                    this.isGravure2Valid = validator('gravure_2');
                },
                deep: true
            }
        },
        mounted() {

        },
        methods: {
            reinitGravure(id) {
                this.item[`gravure_${id}`] = null;
                this.item[`gravure_${id}_message`] = null;
                this.item[`gravure_${id}_emplacement`] = null;
                this.item[`gravure_${id}_type`] = null;
                this.item[`gravure_${id}_typographie`] = null;
            },
            removeSecondGravure() {
                this.hasMultipleGravure = false;
                this.reinitGravure(2);
                this.showFormGravure(1);
            },
            addSecondGravure() {
                if (this.isGravure1Valid) {
                    this.hasMultipleGravure = true;
                    this.showFormGravure(2);
                    return;
                }
            },
            showFormGravure(id) {
                this.currentGravureEdition = id;
            },
            closeModal() {
                ModalStore.get('product-gravure-modal').close();
            },
            validate() {
                const errorIds = [];

                if (this.hasMultipleGravure) {
                    if(!this.isGravure1Valid) {
                        errorIds.push('invalid-1');
                    }

                    if(!this.isGravure2Valid) {
                        errorIds.push('invalid-2');
                    }
                } else {
                    if (!this.isGravure1Valid) {
                        errorIds.push('invalid');
                    }
                }

                if (!this.item.mentions) {
                    errorIds.push('terms');
                }

                if (errorIds.length > 0) {
                    this.showErrors(errorIds);
                    return;
                }

                this.hideErrors();
                this.saveCustomizations();
            },
            hideErrors() {
                this.errorMessages = [];
                this.showErrorMessage = false;
            },
            showErrors(idsMessage) {
                this.errorMessages = idsMessage;
                this.showErrorMessage = true;
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading() {
                this.loading = false;
            },
            cancelCustomizations() {
                this.setCustomization(false);
                this.reinitGravure(1);
                this.reinitGravure(2);
                this.closeModal();
            },
            saveCustomizations() {
                this.startLoading();
                this.prepareItem();

                let url = prestashop.urls.base_url + 'modules/md_gravure/ajax.php';

                let data = {
                    'action': 'saveCustomizations',
                    'item': this.item,
                    'customizations_fields': this.customizations_fields
                };

                return axios({
                    method: 'post',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                }).then((response) => {
                    this.stopLoading();
                    if(response.data.id_customization) {
                        this.setCustomization(response.data.id_customization);
                        this.closeModal();
                    } else {
                        this.showErrors(['unknown']);
                    }
                }).catch((error) => {
                    this.stopLoading();
                    this.showErrors(['unknown']);
                });
            },
            setCustomization(id_customization) {
                $('#product_customization_id').val(id_customization);
                this.$emit('setCustomization', id_customization);
            },
            prepareItem() {
                this.item.id_product = $('#product_page_product_id').val();

                if (
                    this.item.gravure_1_message
                    || this.item.gravure_1_emplacement
                    || this.item.gravure_1_type
                    || this.item.gravure_1_typographie
                ) {
                    this.item.gravure_1 = this.customizations_fields.gravure_1.label;
                }

                if (
                    this.item.gravure_2_message
                    || this.item.gravure_2_emplacement
                    || this.item.gravure_2_type
                    || this.item.gravure_2_typographie
                ) {
                    this.item.gravure_2 = this.customizations_fields.gravure_2.label;
                }
            },
        }
    }
</script>
