import $ from 'jquery';

export default class SearchBar {
  static init() {
    this.$searchWidget = $('#search_widget');

    if (this.$searchWidget.length === 0) {
      return;
    }

    this.$searchInput = this.$searchWidget.find('input[type=text]');
    this.searchURL = this.$searchWidget.attr('data-search-controller-url');
    this.$resultArea = this.$searchWidget.find('.results');
    this.$resultProducts = this.$resultArea.find('.list');

    this.debounceTimeout = false;
    this.debounceDuration = 500;

    this.$searchInput.on('keyup', (event) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        this.onDebounce(event.currentTarget.value);
        this.debounceTimeout = null;
      }, this.debounceDuration);
    });
  }

  static onDebounce(value) {
    this.startLoading();

    this.currentSearchedTerm = value;
    this.searchTerm(value)
      .then((res) => {
        if (this.currentSearchedTerm !== res.term) {
          return;
        }

        this.stopLoading();
        this.showResults(res.products);
      });
  }

  static startLoading() {
    this.$resultArea.addClass('js-loading');
  }

  static stopLoading() {
    this.$resultArea.removeClass('js-loading');
  }

  static showResults(products) {
    if (products.length === 0) {
      this.$resultArea.addClass('js-empty');
      return;
    }
    this.$resultArea.removeClass('js-empty');
    this.$resultProducts.html('');

    products.forEach((product) => {
      const productHTML = $('<a>')
        .addClass('product-link')
        .attr('href', product.url)
        .html(product.name);

      this.$resultProducts.append(productHTML);
    });
  }

  static searchTerm(term) {
    return $.post(this.searchURL, {
      s: term,
      resultsPerPage: -1,
    }, null, 'json')
      .then(res => ({
        products: res.products,
        term,
      }));
  }
}
