var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-giftcard-container" }, [
    _c("p", { staticClass: "variant-label" }, [
      _vm._v(_vm._s(_vm.wordings.label))
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "giftcard-button",
        attrs: { type: "button", "data-open-modal": "product-giftcard-modal" }
      },
      [
        _vm._v("\n        " + _vm._s(_vm.wordings.message) + "\n        "),
        !this.hasMessage
          ? _c("i", { staticClass: "icon icon-arrow-right" })
          : _vm._e(),
        _vm._v(" "),
        this.hasMessage ? _c("i", { staticClass: "icon icon-pen" }) : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal-container half-modal hidden",
        attrs: {
          id: "product-giftcard-modal",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "overlay" }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("div", {
            staticClass: "close-btn",
            attrs: { "data-close-modal": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-content-wrapper" },
            [
              _c(
                "md-product-giftcard-modal",
                {
                  attrs: {
                    customizations_fields: _vm.customizations_fields,
                    wordings: _vm.wordings
                  },
                  on: { setCustomization: _vm.setCustomization }
                },
                [
                  _vm._t("title", null, { slot: "title" }),
                  _vm._v(" "),
                  _vm._t("desc", null, { slot: "desc" })
                ],
                2
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }