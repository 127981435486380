export default class CookiesBanner {
  static init() {
    this.tarteaucitron = window.tarteaucitron;

    if (!this.tarteaucitron) {
      return;
    }

    this.tarteaucitron.init({
      privacyUrl: '', /* Privacy policy url */

      hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
      cookieName: 'tarteaucitron', /* Cookie name */

      orientation: 'bottom', /* Banner position (top - bottom) */

      showAlertSmall: false, /* Show the small banner on bottom right */
      cookieslist: false, /* Show the cookie list */

      closePopup: false, /* Show a close X on the banner */

      showIcon: false, /* Show cookie icon to manage cookies */
      iconPosition: 'BottomRight', /* BottomRight, BottomLeft, TopRight and TopLeft */

      adblocker: false, /* Show a Warning if an adblocker is detected */

      DenyAllCta: true, /* Show the deny all button */
      AcceptAllCta: true, /* Show the accept all button when highPrivacy on */
      highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */

      handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */

      removeCredit: true, /* Remove credit link */
      moreInfoLink: true, /* Show more info link */

      useExternalCss: true,
      useExternalJs: false,

      // cookieDomain: '.my-multisite-domaine.fr', /* Shared cookie for multisite */

      readmoreLink: '', /* Change the default readmore link */

      mandatory: true, /* Show a message about mandatory cookies */
    });

    this.tarteaucitron.user.gtagUa = 'G-JDSPMZWFJN';
    (this.tarteaucitron.job = this.tarteaucitron.job || []).push('gtag');

    this.tarteaucitron.user.googletagmanagerId = 'GTM-WKLXTJQ';
    (this.tarteaucitron.job = this.tarteaucitron.job || []).push('googletagmanager');
  }
}
