import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import AnimationUtils from './animation-utils';

export default class InnerImageParalax {
  static init() {
    const $imageContainers = $('.image-container.inner-paralax');

    this.controller = new ScrollMagic.Controller();

    $imageContainers.each((index, imageContainer) => {
      const $imageContainer = $(imageContainer);
      if ($imageContainer.hasClass('js-paralax-init')) {
        return;
      }

      $imageContainer.addClass('js-paralax-init');

      const paralaxAmount = $imageContainer.data('paralax') || 20;
      const duration = $imageContainer.data('paralax-duration') || '150%';
      const trigger = $imageContainer.data('paralax-trigger') || 1;

      AnimationUtils.innerImageParalax(
        this.controller,
        imageContainer,
        paralaxAmount,
        duration,
        trigger,
      );
    });
  }
}
