<template>
    <div class="product-gravure-container">
        <div class="no-gravure" v-show="!hasGravure">
            <span class="label"><slot name="text-intro"></slot></span>

            <button type="button" class="gravure-button" data-open-modal="product-gravure-modal">
                <i class="icon icon-gravure"></i>
                {{wordings.engraving}}(s)
            </button>
        </div>

        <div class="has-gravure" v-show="hasGravure">
            <div class="update-gravure" data-open-modal="product-gravure-modal">
                <span class="label">{{wordings.engraving}}</span>
                <i class="icon icon-pen"></i>
            </div>
            <div class="remove-gravure" @click="resetCustomization" :title="wordings.reset"><i class="icon icon-cross"></i> <span class="sr-only">{{wordings.reset}}</span></div>
        </div>

        <div class="modal-container half-modal hidden" id="product-gravure-modal"  role="dialog" aria-hidden="true">
            <div class="overlay"></div>
            <div class="modal-content">
                <div class="close-btn" data-close-modal></div>
                <div class="modal-content-wrapper">
                    <md-product-gravure-modal
                        :price_gravure="price_gravure"
                        :customizations_fields="customizations_fields"
                        :currency="currency"
                        :placeholders="placeholders"
                        :images_path="images_path"
                        :wordings="wordings"
                        @setCustomization="setCustomization"
                        ref="modal"
                    >
                        <slot slot="title" name="title"></slot>
                        <slot slot="desc" name="desc"></slot>
                        <slot slot="mentions" name="mentions"></slot>
                        <slot slot="text-validate" name="text-validate"></slot>
                        <slot slot="error-message" name="error-message"></slot>
                        <slot slot="text-add-gravure-2" name="text-add-gravure-2"></slot>
                    </md-product-gravure-modal>
                </div>
            </div>
            <div class="modal-content modal-left-content">
                <div class="modal-content-wrapper">
                    <slot name="gallery-content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            price_gravure: {
                type: Number,
                default: 10,
            },
            customizations_fields: {
                type: Object
            },
            currency: {
                type: Object
            },
            placeholders: {
                type: Object
            },
            wordings: {
                type: Object
            },
            images_path: {
                type: String,
            },
        },
        data() {
            return {
                hasGravure: false,
            }
        },
        mounted() {

        },
        methods: {
            setCustomization(id_customization) {
                if (!id_customization) {
                    this.hasGravure = false;
                } else {
                    this.hasGravure = true;
                }
            },
            resetCustomization() {
                this.$refs.modal.cancelCustomizations();
            },
        }
    }
</script>
