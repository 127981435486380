import $ from 'jquery';
import Utils from '../utils/utils';

export default class BandeauPromo {
  static init() {
    this.$bandeau = $('.bandeau-promo');

    if (this.$bandeau.length === 0) {
      return;
    }

    const cookie = Utils.getCookie('bandeau-promo');

    if (cookie) {
      return;
    }

    this.$header = $('.main-header');
    this.$carouselClose = this.$bandeau.find('.close');

    this.$carouselClose.on('click', (event) => {
      event.stopPropagation();
      this.handleClose();
    });

    this.showBandeau();
  }

  static showBandeau() {
    this.$header.addClass('bandeau-visible');
    this.$bandeau.addClass('js-visible');
  }

  static hideBandeau() {
    this.$header.removeClass('bandeau-visible');
    this.$bandeau.removeClass('js-visible');
  }

  static handleClose() {
    this.hideBandeau();
    Utils.setCookie('bandeau-promo', true, 1);
  }
}
