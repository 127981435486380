import $ from 'jquery';
import ProductGallery from '../components/product-gallery';

export default class PageProduct {
  static init() {
    this.$page = $('body.page-product');

    if (this.$page.length === 0) {
      return;
    }

    const $galleries = $('.js-product-gallery');

    $galleries.each((index, element) => {
      // eslint-disable-next-line no-unused-vars
      const gallery = new ProductGallery($(element));
    });
    // $('.giftcard-button').trigger('click');
  }
}
