import $ from 'jquery';
import { TimelineLite, Power1 } from 'gsap';
import Utils from './utils';
import ModalStore from './modal-store';

export default class Modal {
  constructor($modalContainer, closeCallback) {
    const id = $modalContainer.attr('id');

    if (ModalStore.exists(id)) {
      return ModalStore.get(id);
    }

    this.$modalContainer = $modalContainer;

    this.$overlay = this.$modalContainer.find('.overlay');
    this.$modalContent = this.$modalContainer.find('.modal-content:not(.modal-left-content)');
    this.$modalContentLeft = this.$modalContainer.find('.modal-content.modal-left-content');

    this.$firstInput = this.$modalContent.find('input:not([type=hidden])').first();
    this.$youtubeIFrame = this.$modalContainer.find('iframe[src*="youtube"]');

    if (this.$youtubeIFrame.length > 0) {
      let src = this.$youtubeIFrame.attr('src');

      if (src.indexOf('enablejsapi') < 0) {
        src += `${src.indexOf('?') > 0 ? '&' : '?'}enablejsapi=1`;
        this.$youtubeIFrame.attr('src', src);
      }

      if (src.indexOf('autoplay') < 0) {
        src += `${src.indexOf('?') > 0 ? '&' : '?'}autoplay=1`;
        this.$youtubeIFrame.attr('src', src);
      }
    }

    if (!this.$overlay.length === 0 || !this.$modalContent.length === 0) {
      console.error('No modal available');
      return false;
    }

    this.closeCallback = closeCallback || (() => {});

    this.$modalContainer.find('.overlay').on('click', (e) => {
      e.preventDefault();
      this.close();
    });

    this.$modalContainer.find('[data-close-modal]').on('click', (e) => {
      e.preventDefault();
      this.close();
    });

    ModalStore.add(id, this);
  }

  open() {
    this.$modalContainer.removeClass('hidden');
    Utils.blockWindowScroll();

    if (this.$modalContainer.parents('.main-content').length > 0) {
      $('body').addClass('js-modal-open');
    }

    if (this.$modalContainer.hasClass('half-modal')) {
      this.animateHalfModalApparition();
    } else {
      this.animateNormalModalApparition();
    }
  }

  playVideo() {
    this.$youtubeIFrame[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  }

  stopVideo() {
    this.$youtubeIFrame[0].contentWindow.postMessage(JSON.stringify({
      event: 'command',
      func: 'stopVideo',
    }), '*');
  }

  animateHalfModalApparition() {
    const timeline = new TimelineLite({
      onComplete: () => {
        if (this.$youtubeIFrame.length > 0) {
          this.playVideo();
        }

        if (this.$firstInput.length > 0) {
          this.$firstInput.focus();
        }
      },
    });

    timeline
      .fromTo(this.$overlay, 0.5, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
      }, 0)
      .fromTo(this.$modalContent, 0.5, {
        autoAlpha: 1,
        x: '100%',
      }, {
        autoAlpha: 1,
        x: '0%',
        ease: Power1.easeInOut,
      }, 0);

    if (this.$modalContentLeft.length > 0) {
      timeline
        .fromTo(this.$modalContentLeft, 0.5, {
          autoAlpha: 1,
          x: '-100%',
        }, {
          autoAlpha: 1,
          x: '0%',
          ease: Power1.easeInOut,
        }, '-=0.2');
    }
  }

  animateNormalModalApparition() {
    const timeline = new TimelineLite({
      onComplete: () => {
        if (this.$youtubeIFrame.length > 0) {
          this.playVideo();
        }

        if (this.$firstInput.length > 0) {
          this.$firstInput.focus();
        }
      },
    });

    timeline
      .fromTo(this.$modalContent, 0.3, {
        autoAlpha: 0,
        y: 30,
      }, {
        autoAlpha: 1,
        y: 0,
      }, 0)
      .fromTo(this.$overlay, 0.3, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
      });
  }

  close() {
    Utils.restoreWindowScroll();
    $('body').removeClass('js-modal-open');
    const timeline = new TimelineLite({
      onComplete: () => {
        this.$modalContainer.addClass('hidden');
        this.closeCallback();
      },
    });

    if (this.$youtubeIFrame.length > 0) {
      this.stopVideo();
    }

    const $childrens = this.$modalContainer.children();

    timeline
      .fromTo($childrens, 0.5, {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
      });
  }
}
