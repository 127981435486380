import $ from 'jquery';
import Utils from './utils';

export default class MobileMenu {
  static init() {
    this.$header = $('.main-header');
    this.$menuButton = this.$header.find('.mobile-menu-button');
    this.$mobileMenu = this.$header.find('.mobile-menu-container');
    this.$itemsWithSubmenus = this.$mobileMenu.find('.menu-item.has-submenu');
    this.$submenusLinks = this.$itemsWithSubmenus.find('.menu-link');
    this.$submenus = this.$itemsWithSubmenus.find('.submenu');
    this.$submenusBack = this.$submenus.find('.back-submenu');

    this.isMenuVisible = this.$header.hasClass('menu-visible');

    this.$menuButton.on('click', () => this.toggleMenu());

    this.initSubmenuNavigation();
  }

  static toggleMenu() {
    this.$header.toggleClass('menu-visible', !this.isMenuVisible);
    this.isMenuVisible = !this.isMenuVisible;

    if (this.isMenuVisible) {
      Utils.blockWindowScroll();
    } else {
      Utils.restoreWindowScroll();
      this.hideSubmenu();
    }
  }

  static initSubmenuNavigation() {
    this.$submenusLinks.on('click', (event) => {
      event.preventDefault();
      const $menuItem = $(event.currentTarget).parent('.menu-item');
      const $submenu = $menuItem.find('.submenu');

      this.showSubmenu($submenu);
    });

    this.$submenusBack.on('click', (event) => {
      event.preventDefault();
      this.hideSubmenu();
    });
  }

  static showSubmenu($submenu) {
    $submenu.addClass('current');
  }

  static hideSubmenu() {
    this.$submenus.filter('.current').removeClass('current');
  }
}
