var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-gravure-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hasGravure,
            expression: "!hasGravure"
          }
        ],
        staticClass: "no-gravure"
      },
      [
        _c("span", { staticClass: "label" }, [_vm._t("text-intro")], 2),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "gravure-button",
            attrs: {
              type: "button",
              "data-open-modal": "product-gravure-modal"
            }
          },
          [
            _c("i", { staticClass: "icon icon-gravure" }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.wordings.engraving) +
                "(s)\n        "
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasGravure,
            expression: "hasGravure"
          }
        ],
        staticClass: "has-gravure"
      },
      [
        _c(
          "div",
          {
            staticClass: "update-gravure",
            attrs: { "data-open-modal": "product-gravure-modal" }
          },
          [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.wordings.engraving))
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "icon icon-pen" })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "remove-gravure",
            attrs: { title: _vm.wordings.reset },
            on: { click: _vm.resetCustomization }
          },
          [
            _c("i", { staticClass: "icon icon-cross" }),
            _vm._v(" "),
            _c("span", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.wordings.reset))
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal-container half-modal hidden",
        attrs: {
          id: "product-gravure-modal",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "overlay" }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("div", {
            staticClass: "close-btn",
            attrs: { "data-close-modal": "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-content-wrapper" },
            [
              _c(
                "md-product-gravure-modal",
                {
                  ref: "modal",
                  attrs: {
                    price_gravure: _vm.price_gravure,
                    customizations_fields: _vm.customizations_fields,
                    currency: _vm.currency,
                    placeholders: _vm.placeholders,
                    images_path: _vm.images_path,
                    wordings: _vm.wordings
                  },
                  on: { setCustomization: _vm.setCustomization }
                },
                [
                  _vm._t("title", null, { slot: "title" }),
                  _vm._v(" "),
                  _vm._t("desc", null, { slot: "desc" }),
                  _vm._v(" "),
                  _vm._t("mentions", null, { slot: "mentions" }),
                  _vm._v(" "),
                  _vm._t("text-validate", null, { slot: "text-validate" }),
                  _vm._v(" "),
                  _vm._t("error-message", null, { slot: "error-message" }),
                  _vm._v(" "),
                  _vm._t("text-add-gravure-2", null, {
                    slot: "text-add-gravure-2"
                  })
                ],
                2
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content modal-left-content" }, [
          _c(
            "div",
            { staticClass: "modal-content-wrapper" },
            [_vm._t("gallery-content")],
            2
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }