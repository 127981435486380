import $ from 'jquery';
/* eslint-disable-next-line */
import prestashop from 'prestashop';
import PageProduct from './pages/page-product';

$(document).ready(() => {
  prestashop.on('updatedProduct', () => {
    PageProduct.init();
  });
});
