<template>
  <div class="ring-size-selection form-modal">
    <p class="modal-title"><slot name="title"></slot></p>

    <div class="rte-content intro">
      <slot name="desc"></slot>
    </div>

    <div class="checkboxes-container">
      <div v-for="item in attributes" :key="item.id_attribute" class="form-control radio-container">
        <input
          type="radio"
          name="size_id_attribute"
          :id="'attribute_' + item.id_attribute"
          :value="item.id_attribute"
          :checked="selected_size_id == item.id_attribute"
          v-on:click="select(item.id_attribute, item.name, $event)"
        />
        <label :for="'attribute_' + item.id_attribute" class="control-label">
          {{ item.name }}
        </label>
      </div>
    </div>

    <button class="btn" type="button" v-on:click="closePopup">
      <span class="btn-content">
        <slot name="text-validate"></slot>
        <i class="icon icon-check"></i>
      </span>
    </button>
  </div>
</template>

<script>
import ModalStore from '../../../utils/modal-store.js';

export default {
  props: {
    group_original: {
      type: Object
    },
    attributes_order: {
      type: Array
    },
    id_attribute_group: {
      type: String
    }
  },
  data() {
    return {
      has_error: false,
      selected_size_id: null,
      selected_size_name: null
    };
  },
  computed: {
    attributes() {
      let attributes = [];
      this.attributes_order.forEach((item, index) => {

        if(this.group_original.attributes[item].selected) {
          this.selected_size_id = item;
          this.selected_size_name = this.group_original.attributes[item].name;
        }

        attributes.push({
          id_attribute: item,
          name: this.group_original.attributes[item].name,
          quantity: this.group_original.attributes_quantity[item],
          selected: this.group_original.attributes[item].selected,
        });
      });

      return attributes;
    }
  },
  watch: {
    has_error(after, before) {
      if (after) {

      }
    }
  },
  mounted() { },
  methods: {
    showError() {
      alert("Vous devez choisir une taille.");
    },
    select(id, name, event) {
      this.selected_size_id = id;
      this.selected_size_name = name;

      $('.js-attribute-group-' + this.id_attribute_group).val(this.selected_size_id);
      $('.js-selected-attribute-' + this.id_attribute_group + '-container .js-selected-attribute-value').text(this.selected_size_name);

      // event
      prestashop.emit('updateProduct', {
        eventType: 'updatedProductCombination',
        event: event,
        // Following variables are not used anymore, but kept for backward compatibility
        resp: {},
        reason: {
          productUrl: prestashop.urls.pages.product || '',
        },
      });
    },
    closePopup() {
      ModalStore.get('product-ring-size-selection-modal-' + this.id_attribute_group).close();
    }
  }
};
</script>
