var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-giftcard-reception-container" }, [
    _c("p", {
      staticClass: "variant-label",
      domProps: { innerHTML: _vm._s(_vm.wordings.label) }
    }),
    _vm._v(" "),
    _c("form", [
      _c("div", { staticClass: "radio-variant" }, [
        _c("div", { staticClass: "form-control radio-container" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.reception_mode,
                expression: "item.reception_mode"
              }
            ],
            attrs: {
              type: "radio",
              name: "reception_mode",
              id: "reception_mode_email",
              value: "email"
            },
            domProps: {
              checked: _vm.reception_mode == "email",
              checked: _vm._q(_vm.item.reception_mode, "email")
            },
            on: {
              change: [
                function($event) {
                  return _vm.$set(_vm.item, "reception_mode", "email")
                },
                _vm.changeReceptionMode
              ]
            }
          }),
          _vm._v(" "),
          _c("label", {
            staticClass: "control-label",
            attrs: { for: "reception_mode_email" },
            domProps: { innerHTML: _vm._s(_vm.wordings.reception_email) }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "email-container",
            class: { visible: _vm.reception_mode == "email" }
          },
          [
            _c("div", { staticClass: "floating-label" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.beneficiare_email,
                    expression: "item.beneficiare_email"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "beneficiare_email",
                  id: "beneficiare_email",
                  placeholder: "example@mail.com"
                },
                domProps: { value: _vm.item.beneficiare_email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.item, "beneficiare_email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "form-control-label",
                attrs: { for: "beneficiare_email" },
                domProps: { innerHTML: _vm._s(_vm.wordings.email) }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-control radio-container" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.reception_mode,
                expression: "item.reception_mode"
              }
            ],
            attrs: {
              type: "radio",
              name: "reception_mode",
              id: "reception_mode_coffret",
              value: "coffret"
            },
            domProps: {
              checked: _vm.reception_mode == "coffret",
              checked: _vm._q(_vm.item.reception_mode, "coffret")
            },
            on: {
              change: [
                function($event) {
                  return _vm.$set(_vm.item, "reception_mode", "coffret")
                },
                _vm.changeReceptionMode
              ]
            }
          }),
          _vm._v(" "),
          _c("label", {
            staticClass: "control-label",
            attrs: { for: "reception_mode_coffret" },
            domProps: {
              innerHTML: _vm._s(
                _vm.wordings.reception_coffret +
                  " (+" +
                  _vm.customizations_fields.coffret.price +
                  " " +
                  _vm.currency.sign +
                  ")"
              )
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }