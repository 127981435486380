var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ring-size-selection form-modal" }, [
    _c("p", { staticClass: "modal-title" }, [_vm._t("title")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "rte-content intro" }, [_vm._t("desc")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "checkboxes-container" },
      _vm._l(_vm.attributes, function(item) {
        return _c(
          "div",
          {
            key: item.id_attribute,
            staticClass: "form-control radio-container"
          },
          [
            _c("input", {
              attrs: {
                type: "radio",
                name: "size_id_attribute",
                id: "attribute_" + item.id_attribute
              },
              domProps: {
                value: item.id_attribute,
                checked: _vm.selected_size_id == item.id_attribute
              },
              on: {
                click: function($event) {
                  return _vm.select(item.id_attribute, item.name, $event)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "control-label",
                attrs: { for: "attribute_" + item.id_attribute }
              },
              [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        attrs: { type: "button" },
        on: { click: _vm.closePopup }
      },
      [
        _c(
          "span",
          { staticClass: "btn-content" },
          [
            _vm._t("text-validate"),
            _vm._v(" "),
            _c("i", { staticClass: "icon icon-check" })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }