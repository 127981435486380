import $ from 'jquery';
import {
  TimelineLite,
  Power0,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import Utils from '../utils/utils';

export default class HomeInstagram {
  static init() {
    this.$section = $('.home-instagram');

    if (this.$section.length === 0) {
      return;
    }

    this.$wrapperInstagram = $('#js-instagram-inner');
    this.$images = this.$section.find('.image-container');

    this.controller = new ScrollMagic.Controller();
    this.dateNow = Date.now();
    this.initLogoParalax();
    this.initParalax();
  }

  static initLogoParalax() {
    const $logoParalax = this.$section.find('.logo-background');
    const timeline = new TimelineLite();

    timeline
      .fromTo($logoParalax[0], 0.5, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
      }, 0)
      .fromTo($logoParalax[0], 5, {
        x: 0,
      }, {
        x: '-50%',
        ease: Power0.easeNone,
      }, 0);

    // Paralax
    new ScrollMagic.Scene({
      triggerElement: this.$section[0],
      triggerHook: 0.5,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initParalax() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }
    const paralaxRatios = [
      180,
      120,
      40,
      80,
    ];

    const timeline = new TimelineLite();

    this.$images.each((index, image) => {
      let paralaxRatio = paralaxRatios[index];

      if ($('body').width() < Utils.breakpoints().lg) {
        paralaxRatio = Math.ceil(paralaxRatio / 5);
      }

      timeline
        .fromTo(image, 1, {
          y: paralaxRatio,
        }, {
          y: -paralaxRatio,
          ease: Power0.easeNone,
        }, 0);
    });

    new ScrollMagic.Scene({
      triggerElement: this.$section[0],
      triggerHook: 1,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }
}
