import $ from 'jquery';
import ProductsList from './components/products-list';

function updateProductListDOM(data) {
  $('#search_filters').replaceWith(data.rendered_facets);
  $('#js-active-search-filters').html(data.rendered_active_filters);
  $('#js-product-list-top').html(data.rendered_products_top);
  $('#js-product-list').html(data.rendered_products);
  $('#js-product-list-bottom').html(data.rendered_products_bottom);
  if (data.rendered_products_header) {
    $('#js-product-list-header').html(data.rendered_products_header);
  }
  ProductsList.init();
}

$(document).ready(() => {
  $('body').on('click', '#search_filter_toggler', () => {
    $('#search_filters_wrapper').removeClass('hidden-sm-down');
    $('#content-wrapper').addClass('hidden-sm-down');
    $('#footer').addClass('hidden-sm-down');
  });
  $('#search_filter_controls .clear').on('click', () => {
    $('#search_filters_wrapper').addClass('hidden-sm-down');
    $('#content-wrapper').removeClass('hidden-sm-down');
    $('#footer').removeClass('hidden-sm-down');
  });
  $('#search_filter_controls .ok').on('click', () => {
    $('#search_filters_wrapper').addClass('hidden-sm-down');
    $('#content-wrapper').removeClass('hidden-sm-down');
    $('#footer').removeClass('hidden-sm-down');
  });

  const parseSearchUrl = (event) => {
    if (event.target.dataset.searchUrl !== undefined) {
      return event.target.dataset.searchUrl;
    }

    if ($(event.target).parent()[0].dataset.searchUrl === undefined) {
      throw new Error('Can not parse search URL');
    }

    return $(event.target).parent()[0].dataset.searchUrl;
  };

  $('body').on('change', '#search_filters input[data-search-url]', (event) => {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-filters-clear-all', (event) => {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-link', (event) => {
    event.preventDefault();
    prestashop.emit('updateFacets', $(event.target).closest('a').get(0).href);
  });

  $('body').on('change', '#search_filters select', (event) => {
    const form = $(event.target).closest('form');
    prestashop.emit('updateFacets', `?${form.serialize()}`);
  });

  prestashop.on('updateProductList', (data) => {
    updateProductListDOM(data);
  });
});
