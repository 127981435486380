import $ from 'jquery';
import Modal from '../utils/modal';

export default class ModalOpener {
  static init() {
    this.$modalOpeners = $('[data-open-modal]');

    this.$modalOpeners.on('click', (event) => {
      const modalId = $(event.currentTarget).data('open-modal');

      const $modalElement = $(`#${modalId}`);

      const modal = new Modal($modalElement);
      modal.open();
    });
  }
}
