import $ from 'jquery';

export default class Utils {
  static blockWindowScroll() {
    this.beforeFixingScrollAmount = this.getScrollAmount();
    $('body').addClass('block-scroll');
    document.body.style.position = 'fixed';
    document.body.style.overflowY = 'scroll';
    document.body.style.overflowX = 'hidden';
    document.body.style.width = '100%';
    document.body.style.marginTop = `${-this.beforeFixingScrollAmount}px`;
  }

  static restoreWindowScroll() {
    document.body.style.position = '';
    document.body.style.overflow = '';
    document.body.style.width = '';
    document.body.style.marginTop = '';

    window.scrollTo(0, this.beforeFixingScrollAmount);
    $('body').removeClass('block-scroll');
  }

  static restoreWindowScrollToTop() {
    document.body.style.position = '';
    document.body.style.overflow = '';
    document.body.style.width = '';
    document.body.style.marginTop = '';

    window.scrollTo(0, 0);
    $('body').removeClass('block-scroll');
  }

  static getScrollAmount() {
    return window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop
      || 0;
  }

  // static overlaps($object1, $object2) {
  //   let overlaps = false;

  //   $object1.each((index, element) => {
  //     const bounds = $(element).offset();
  //     bounds.right = bounds.left + $(element).outerWidth();
  //     bounds.bottom = bounds.top + $(element).outerHeight();

  //     const compare = $($object2).offset();
  //     compare.right = compare.left + $($object2).outerWidth();
  //     compare.bottom = compare.top + $($object2).outerHeight();

  //     if (!(compare.right < bounds.left ||
  //       compare.left > bounds.right ||
  //       compare.bottom < bounds.top ||
  //       compare.top > bounds.bottom)
  //     ) {
  //       overlaps = true;
  //     }
  //     // return false when overlaps to avois parsing all elements
  //     return !overlaps;
  //   });

  //   return overlaps;
  // }

  // static isVisible($element) {
  //   const threshold = $(window).scrollTop() + $(window).height();
  //   return $element.eq(0).offset().top < threshold;
  // }

  // static isFullyVisible($element) {
  //   const $window = $(window);
  //   const docViewTop = $window.scrollTop();
  //   const docViewBottom = docViewTop + $window.height();

  //   const elemTop = $element.offset().top;
  //   const elemBottom = elemTop + $element.height();

  //   return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  // }

  static randomInt(min, max) {
    const minRounded = Math.ceil(min);
    const maxRounded = Math.floor(max);
    return Math.floor(Math.random() * (maxRounded - minRounded)) + minRounded;
  }

  /**
   * Return a random int bteween min and max that is a multiple of the base
   * @param {*} min
   * @param {*} max
   * @param {*} base
   */
  static randomIntMultiple(min, max, base) {
    const random = this.randomInt(min / base, max / base);
    return random * base;
  }

  static breakpoints() {
    return {
      xs: 0,
      sm: 570,
      md: 770,
      lg: 990,
      xl: 1200,
    };
  }

  static maxWidths() {
    return {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140,
    };
  }

  static isWindowLarger(breakpoint) {
    const breakpointValue = Utils.breakpoints()[breakpoint];

    if (!breakpointValue) {
      console.error('No corresponding breakpoint', breakpoint);
      return false;
    }

    return window.innerWidth >= breakpointValue;
  }

  static isWindowSmaller(breakpoint) {
    const breakpointValue = Utils.breakpoints()[breakpoint];

    if (!breakpointValue) {
      console.error('No corresponding breakpoint', breakpoint);
      return false;
    }

    return window.innerWidth < breakpointValue;
  }

  static getCurrentBreakpoint() {
    const breakpoints = this.breakpoints();
    let currentBreakpoint;

    Object.keys(breakpoints).forEach((breakpoint) => {
      if (window.innerWidth > breakpoints[breakpoint]) {
        currentBreakpoint = breakpoint;
      }
    });

    return currentBreakpoint;
  }

  static roundNumber(number, nbDecimals) {
    const ratio = 10 ** nbDecimals;
    return Math.round(number * ratio) / ratio;
  }

  /**
  * Create a cookie
  */
  static setCookie(cookieName, cookieValue, durationInDay) {
    const today = new Date();
    const expires = new Date();
    expires.setTime(today.getTime() + (durationInDay * 24 * 60 * 60 * 1000));
    document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)};expires=${expires.toGMTString()};path=/;`;
  }

  /**
  * Check if a cookie exists, if yes return its value
  */
  static getCookie(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  /* eslint-disable no-param-reassign */
  static getAutoHeight(vanillaElement) {
    const heightBackup = vanillaElement.style.height;
    const displayBackup = vanillaElement.style.display;
    vanillaElement.style.height = 'auto';
    vanillaElement.style.display = 'block';
    const autoHeight = vanillaElement.offsetHeight;
    vanillaElement.style.height = heightBackup;
    vanillaElement.style.display = displayBackup;

    return autoHeight;
  }
  /* eslint-disable no-param-reassign */
}
