import $ from 'jquery';

export default class SelectToLink {
  static init() {
    this.$selects = $('select.js-select-to-link');

    if (this.$selects.length === 0) {
      return;
    }

    this.$selects.on('change', (event) => {
      const $select = $(event.currentTarget);
      const url = $select.val();
      window.location.href = url;
    });
  }
}
