var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gravure-modal form-modal",
      class: { loading: _vm.loading }
    },
    [
      _vm.loading ? _c("div", { staticClass: "loader" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "modal-title" },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("span", { staticClass: "small" }, [
            _vm._v(
              "(+" +
                _vm._s(_vm.price_gravure) +
                " " +
                _vm._s(_vm.currency.sign) +
                ")"
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "rte-content intro" }, [_vm._t("desc")], 2),
      _vm._v(" "),
      _vm.hasMultipleGravure
        ? _c(
            "p",
            {
              staticClass: "gravure-title",
              class: { current: _vm.currentGravureEdition === 1 }
            },
            [
              _vm._v(
                "\n        1. " +
                  _vm._s(_vm.wordings.engraving) +
                  " " +
                  _vm._s(
                    _vm.item.gravure_1_message
                      ? "« " + _vm.item.gravure_1_message + " »"
                      : ""
                  ) +
                  "\n        "
              ),
              _c(
                "a",
                {
                  staticClass: "update-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.showFormGravure(1)
                    }
                  }
                },
                [_vm._v("—  " + _vm._s(_vm.wordings.update))]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("md-product-gravure-form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentGravureEdition === 1,
            expression: "currentGravureEdition === 1"
          }
        ],
        ref: "gravure_form_1",
        attrs: {
          gravure_id: 1,
          customizations_fields: _vm.customizations_fields,
          placeholders: _vm.placeholders,
          item: _vm.item,
          images_path: _vm.images_path
        }
      }),
      _vm._v(" "),
      _vm.hasMultipleGravure
        ? _c(
            "p",
            {
              staticClass: "gravure-title",
              class: { current: _vm.currentGravureEdition === 2 }
            },
            [
              _vm._v(
                "\n        2. " +
                  _vm._s(_vm.wordings.engraving) +
                  " " +
                  _vm._s(
                    _vm.item.gravure_2_message
                      ? "« " + _vm.item.gravure_2_message + " »"
                      : ""
                  ) +
                  "\n        "
              ),
              _c(
                "a",
                {
                  staticClass: "remove-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.removeSecondGravure()
                    }
                  }
                },
                [_vm._v("—  " + _vm._s(_vm.wordings.delete))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "update-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.showFormGravure(2)
                    }
                  }
                },
                [_vm._v("—  " + _vm._s(_vm.wordings.update))]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("md-product-gravure-form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentGravureEdition === 2,
            expression: "currentGravureEdition === 2"
          }
        ],
        ref: "gravure_form_2",
        attrs: {
          gravure_id: 2,
          customizations_fields: _vm.customizations_fields,
          placeholders: _vm.placeholders,
          item: _vm.item,
          images_path: _vm.images_path
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canHaveMultipleGravure && !_vm.hasMultipleGravure,
              expression: "canHaveMultipleGravure && !hasMultipleGravure"
            }
          ],
          staticClass: "add-second-gravure",
          attrs: { type: "button", disabled: !_vm.isGravure1Valid },
          on: { click: _vm.addSecondGravure }
        },
        [
          _c("i", { staticClass: "icon icon-gravure plus" }),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm._t("text-add-gravure-2"),
              _vm._v(
                " (+" +
                  _vm._s(_vm.price_gravure) +
                  " " +
                  _vm._s(_vm.currency.sign) +
                  ")"
              )
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "form-group checkbox-container top-align checkbox-mentions"
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.mentions,
                expression: "item.mentions"
              }
            ],
            attrs: {
              type: "checkbox",
              name: "mentions",
              id: "mentions",
              value: "1"
            },
            domProps: {
              checked: Array.isArray(_vm.item.mentions)
                ? _vm._i(_vm.item.mentions, "1") > -1
                : _vm.item.mentions
            },
            on: {
              change: function($event) {
                var $$a = _vm.item.mentions,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "1",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.item, "mentions", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.item,
                        "mentions",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.item, "mentions", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "control-label", attrs: { for: "mentions" } },
            [_vm._t("mentions")],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.validate }
          },
          [
            _c("span", { staticClass: "btn-content" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.wordings.validate) +
                  "\n            "
              ),
              _c("i", { staticClass: "icon icon-check" })
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-red",
            attrs: { type: "button" },
            on: { click: _vm.cancelCustomizations }
          },
          [
            _c("span", { staticClass: "btn-content" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.wordings.reset) + "\n            "
              ),
              _c("i", { staticClass: "icon icon-cross" })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.showErrorMessage
        ? _c(
            "div",
            { staticClass: "error-container" },
            _vm._l(_vm.errorMessages, function(messageId) {
              return _c("p", { key: messageId, staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.wordings.errors[messageId]))
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }