import $ from 'jquery';
import {
  TimelineLite,
  Linear,
  Power2,
  Power1,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import Utils from '../utils/utils';

export default class PageCollection {
  static init() {
    this.$page = $('body.page-collection');
    this.$section = this.$page.find('.section-collection');

    if (this.$page.length === 0 || this.$section.length === 0) {
      return;
    }

    this.$scrollingPanel = this.$page.find('.horizontal-scrolling-panel');

    this.$sectionIntro1 = this.$scrollingPanel.find('.collection-intro-1');
    this.$sectionIntro1Content = this.$sectionIntro1.find('.wrapper');
    this.$sectionIntro2 = this.$scrollingPanel.find('.collection-intro-2');
    this.$sectionIntro2Content = this.$sectionIntro2.find('.wrapper');

    this.initHorizontalScroll();
    this.initVerticalFakeScroll();
    this.initScrollAnimations();
    this.initScrollAnimationsMobile();
  }

  static initHorizontalScroll() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }

    this.controller = new ScrollMagic.Controller({
      vertical: false,
      container: '.horizontal-scrolling-container',
    });
  }

  static initVerticalFakeScroll() {
    if (this.$page.hasClass('touch-device')) {
      return;
    }

    const sectionWidth = this.$scrollingPanel.width();

    const $scrollFaker = this.$section.find('.scroll-faker');

    $scrollFaker.height(sectionWidth);

    $(window).scroll(() => {
      const scroll = $(window).scrollTop();
      if (scroll > 1300) {
        this.$page.addClass('scrolled');
      } else {
        this.$page.removeClass('scrolled');
      }

      this.controller.scrollTo(scroll);
    });
  }

  static initScrollAnimations() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }

    this.initIntroParalax();

    this.initSimpleApparition(this.$sectionIntro1, this.$sectionIntro1Content);
    this.initSimpleApparition(this.$sectionIntro2, this.$sectionIntro2Content);

    this.initBijouIntrosParalax();
    this.initBijouImagesParalax();
    this.initBijouTextApparitions();
    this.initBijouOutroAnimations();
  }

  static initScrollAnimationsMobile() {
    if (Utils.isWindowLarger('md')) {
      return;
    }

    this.controller = new ScrollMagic.Controller();

    this.initIntroParalaxMobile();

    this.initSimpleApparitionMobile(this.$sectionIntro1, this.$sectionIntro1Content);
    this.initSimpleApparitionMobile(this.$sectionIntro2, this.$sectionIntro2Content);

    this.initBijouIntrosParalaxMobile();
    this.initBijouTextApparitionsMobile();
    // this.initBijouOutroAnimationsMobile();
  }

  static initIntroParalax() {
    const timeline = new TimelineLite();
    const $introBlock = this.$scrollingPanel.find('.intro-block');

    timeline
      .fromTo($introBlock, 1, {
        x: 0,
      }, {
        x: 200,
        ease: Power1.easeOut,
      });

    new ScrollMagic.Scene({
      triggerHook: 1,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initIntroParalaxMobile() {
    const timeline = new TimelineLite();
    const $introBlock = this.$scrollingPanel.find('.intro-block');
    const $image = this.$page.find('.image-intro');

    timeline
      .fromTo($introBlock, 1, {
        y: 0,
      }, {
        y: -150,
        ease: Power1.easeOut,
      }, 0)
      .fromTo($image, 1, {
        y: 0,
      }, {
        y: -75,
        ease: Power1.easeOut,
      }, 0);

    new ScrollMagic.Scene({
      triggerHook: 1,
      duration: '100%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initSimpleApparition($triggerElement, $content, hook = 0.5) {
    const timeline = new TimelineLite();

    timeline
      .fromTo($triggerElement, 1, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
      }, 0)
      .fromTo($content, 1, {
        x: 60,
      }, {
        x: 0,
        ease: Power2.easeOut,
      }, 0);

    new ScrollMagic.Scene({
      triggerElement: $triggerElement[0],
      triggerHook: hook,
      reverse: false,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initSimpleApparitionMobile($triggerElement, $content, hook = 0.8) {
    const timeline = new TimelineLite();

    timeline
      .fromTo($triggerElement, 1, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
      }, 0)
      .fromTo($content, 1, {
        y: 30,
      }, {
        y: 0,
        ease: Power1.easeOut,
      }, 0);

    new ScrollMagic.Scene({
      triggerElement: $triggerElement[0],
      triggerHook: hook,
      reverse: false,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initBijouIntrosParalax() {
    const $sections = this.$scrollingPanel.find('.bijou-intro');

    $sections.each((index, section) => {
      const timeline = new TimelineLite();
      const $section = $(section);
      const $largeTitle = $section.find('.titre-large');
      const $image = $section.find('.image');
      const $smallTitle = $section.find('.titre-small');

      timeline
        .fromTo($image, 1, {
          x: 200,
        }, {
          x: -250,
          ease: Power1.easeOut,
        })
        .fromTo($smallTitle, 1, {
          x: 0,
        }, {
          x: 100,
          ease: Linear.easeNone,
        }, 0);

      new ScrollMagic.Scene({
        triggerElement: $largeTitle[0],
        triggerHook: 1,
        duration: '150%',
      })
        .setTween(timeline)
        .addTo(this.controller);
    });
  }

  static initBijouIntrosParalaxMobile() {
    const $sections = this.$scrollingPanel.find('.bijou-intro');

    $sections.each((index, section) => {
      const timeline = new TimelineLite();
      const $section = $(section);
      const $largeTitle = $section.find('.titre-large');
      const $image = $section.find('.image');

      timeline
        .fromTo($largeTitle, 1, {
          x: '-20%',
        }, {
          x: '-70%',
          ease: Linear.easeNone,
        }, 0)
        .fromTo($image, 1, {
          x: 0,
        }, {
          x: -20,
          ease: Power1.easeIn,
        }, 0);

      new ScrollMagic.Scene({
        triggerElement: section,
        triggerHook: 1,
        duration: '120%',
      })
        .setTween(timeline)
        .addTo(this.controller);
    });
  }

  static initBijouImagesParalax() {
    const $sections = this.$scrollingPanel.find('.bijou-images');

    $sections.each((index, section) => {
      const timeline = new TimelineLite();
      const $section = $(section);
      const $image1 = $section.find('.image-1');
      const $image2 = $section.find('.image-2');

      timeline
        .fromTo($image1, 1, {
          x: -200,
        }, {
          x: 50,
          ease: Linear.easeNone,
        }, 0)
        .fromTo($image2, 1, {
          x: -0,
        }, {
          x: 50,
          ease: Linear.easeNone,
        }, 0);

      new ScrollMagic.Scene({
        triggerElement: $section[0],
        triggerHook: 1,
        duration: '150%',
      })
        .setTween(timeline)
        .addTo(this.controller);
    });
  }

  static initBijouTextApparitions() {
    const $sectionsDescription = this.$scrollingPanel.find('.bijou-description');

    $sectionsDescription.each((index, section) => {
      const $section = $(section);
      const $content = $section.find('.wrapper');

      this.initSimpleApparition($section, $content, 0.8);
    });

    const $sectionsDetails = this.$scrollingPanel.find('.bijou-details');

    $sectionsDetails.each((index, section) => {
      const $section = $(section);
      const $content = $section.find('.infos');

      this.initSimpleApparition($section, $content, 0.8);
    });
  }

  static initBijouTextApparitionsMobile() {
    const $sectionsDescription = this.$scrollingPanel.find('.bijou-description');

    $sectionsDescription.each((index, section) => {
      const $section = $(section);
      const $content = $section.find('.wrapper');

      this.initSimpleApparitionMobile($section, $content);
    });

    const $sectionsDetails = this.$scrollingPanel.find('.bijou-details');

    $sectionsDetails.each((index, section) => {
      const $section = $(section);
      const $content = $section.find('.infos');

      this.initSimpleApparitionMobile($section, $content);
    });
  }

  static initBijouOutroAnimations() {
    const $sections = this.$scrollingPanel.find('.bijou-outro');

    $sections.each((index, section) => {
      const timeline = new TimelineLite();
      const $section = $(section);
      const $image = $section.find('.image-outro');

      timeline
        .fromTo($image, 1, {
          scale: 0.8,
        }, {
          scale: 1,
          ease: Linear.easeNone,
        }, 0);

      new ScrollMagic.Scene({
        triggerElement: $section[0],
        triggerHook: 1,
        duration: '150%',
      })
        .setTween(timeline)
        .addTo(this.controller);
    });
  }
}
