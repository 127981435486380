import $ from 'jquery';
import {
  TimelineLite,
  Linear,
} from 'gsap';
import ScrollMagic from 'scrollmagic';
import Utils from '../utils/utils';

export default class HomeSemainier {
  static init() {
    this.$section = $('.home-semainier');

    if (this.$section.length === 0) {
      return;
    }

    this.$imageLeft = this.$section.find('.image-left');
    this.$imageRight = this.$section.find('.image-right');

    this.controller = new ScrollMagic.Controller();

    this.initApparitionOnScroll();
    this.initParalax();
  }

  static initApparitionOnScroll() {
    const timeline = new TimelineLite();

    const $elements = this.$section.find('.semainier-block').children(':not(.dot)');

    timeline
      .staggerFromTo($elements, 0.4, {
        y: 20,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
      }, 0.1);

    new ScrollMagic.Scene({
      triggerElement: this.$section[0],
      triggerHook: 0.5,
      reverse: false,
    })
      .setTween(timeline)
      .addTo(this.controller);
  }

  static initParalax() {
    if (Utils.isWindowSmaller('lg')) {
      return;
    }

    const timeline = new TimelineLite();

    timeline
      .fromTo(this.$imageLeft.find('img'), 1, {
        y: 80,
      }, {
        y: -80,
        ease: Linear.easeNone,
      }, 0)
      .fromTo(this.$imageRight.find('img'), 1, {
        y: -80,
      }, {
        y: 80,
        ease: Linear.easeNone,
      }, 0);

    new ScrollMagic.Scene({
      triggerElement: this.$section[0],
      triggerHook: 1,
      duration: '150%',
    })
      .setTween(timeline)
      .addTo(this.controller);
  }
}
