import $ from 'jquery';
import Modal from '../utils/modal';

prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = (html) => {
  function getBlockCartModal() {
    return $('#blockcart-modal');
  }

  let $blockCartModal = getBlockCartModal();
  if ($blockCartModal.length) {
    $blockCartModal.remove();
  }

  $('body').append(html);

  $blockCartModal = getBlockCartModal();
  const modal = new Modal($blockCartModal, () => {
    prestashop.emit('updateProduct');
  });
  modal.open();
};
