<template>
    <div class="giftcard-modal form-modal" :class="{loading}">
        <div class="loader" v-if="loading"></div>
        <p class="modal-title"><slot name="title"></slot></p>

        <div class="rte-content intro">
            <slot name="desc"></slot>
        </div>

        <form>
            <div class="form-group">
                <div class="floating-label">
                    <input
                        class="input"
                        id="buyername"
                        name="buyername"
                        type="text"
                        :placeholder="wordings.buyername_placeholder"
                        :class="{ error: hasError('buyername') }"
                        v-model="item.expediteur"
                    />
                    <label class="form-control-label" for="buyername" v-html="wordings.buyername"></label>
                </div>
            </div>

            <div class="form-group">
                <div class="floating-label">
                    <input
                        class="input"
                        type="text"
                        name="recipientname"
                        id="recipientname"
                        placeholder=" "
                        :class="{ error: hasError('recipientname') }"
                        v-model="item.beneficiare"
                    />
                    <label for="recipientname" class="form-control-label" v-html="wordings.recipientname"></label>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label form-control-label-textarea" for="message">{{ wordings.text }} <span class="optional" v-html="wordings.text_suffix"></span></label>

                <div class="count-textarea">
                    <textarea
                        rows="5"
                        id="message"
                        name="message"
                        class="form-control form-control-textarea bordered"
                        v-model="item.message"
                        :maxlength="char_max"
                    ></textarea>
                    <span class="count">{{item.message ? item.message.length : 0}} / <span v-html="`${char_max} ${wordings.letters}`"></span></span>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn" type="button" @click="validate">
                    <span class="btn-content">{{ wordings.validate }} <i class="icon icon-arrow-right"></i></span>
                </button>
            </div>

            <div class="error-container" v-if="showErrorMessage">
                <p class="error-message" v-for="messageId in errorMessages" :key="messageId">{{ wordings.errors[messageId] }}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import ModalStore from '../../../utils/modal-store.js';
    import debounce from 'lodash/debounce';
    import axios from 'axios';

    export default {
        props: {
            customizations_fields: {
                type: Object
            },
            wordings: {
                type: Object
            }
        },
        data() {
            return {
                item: {
                    expediteur: null,
                    beneficiare: null,
                    message: null
                },
                char_max: 80,
                char_count: 0,
                errorMessages: [],
                showErrorMessage: false,
                loading: false,
                text: '',
            }
        },
        mounted() {
            prestashop.on('updateCart', (event) => {
                this.setCustomization(null);
            });
        },
        methods: {
            validate() {
                let errorIds = [];
                this.errorMessages = [];
                this.showErrorMessage = false;

                if(!this.item.expediteur) {
                    this.errorMessages.push('buyername');
                }

                if(!this.item.beneficiare) {
                    this.errorMessages.push('recipientname');
                }

                if (this.errorMessages.length > 0) {
                    this.showErrorMessage = true;
                    return;
                }

                this.saveCustomization();
            },
            hasError(name) {
                if(this.errorMessages.indexOf(name) !== -1) {
                    return true;
                }

                return false;
            },
            saveCustomization() {
                this.loading = true;

                this.item.id_product = $('#product_page_product_id').val();

                let url = prestashop.urls.base_url + 'modules/md_giftcard/ajax.php';

                let data = {
                    'action': 'saveCustomizations',
                    'item': this.item,
                    'customizations_fields': this.customizations_fields
                };

                return axios({
                    method: 'post',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                }).then((response) => {
                    this.loading = false;
                    if(response.data.id_customization) {
                        this.setCustomization(response.data.id_customization);
                        this.closeModal();
                    } else {
                        this.showErrors(['unknown']);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.showErrors(['unknown']);
                });
            },
            closeModal() {
                ModalStore.get('product-giftcard-modal').close();
            },
            setCustomization(id_customization) {
                $('#product_customization_id').val(id_customization);
                this.$emit('setCustomization', id_customization);
            },
            showErrors(idsMessage) {
                this.errorMessages = idsMessage;
                this.showErrorMessage = true;
            }
        }
    }
</script>